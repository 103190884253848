import React, { useEffect, useState } from "react";
import apiService from "../../services/api.service";
import Chatbot from "../Chatbot";

function Layout() {
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [userId, setUserId] = useState(localStorage?.getItem("userId"));
  const [userRole, setUserRole] = useState(localStorage?.getItem("userRole"));
  useEffect(() => {
    if (userId) {
      const fetchPurchasedCourses = async () => {
        try {
          const response = await apiService.getPurchasedCourses(userId);
          if (response && response.data) {
            setPurchasedCourses(response.data);
          }
        } catch (error) {
          console.error("Error fetching purchased courses:", error);
        }
      };
      fetchPurchasedCourses();
    }
  }, [userId]);
  return (
    <div>
      {(userRole === "User" || userRole === "user") &&
        purchasedCourses &&
        purchasedCourses.length > 0 && <Chatbot />}
    </div>
  );
}

export default Layout;
