// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: "Nanum Gothic", sans-serif !important;
    background-color: #F5FCF8 !important;
}

.course-card {
    width: 100%;
    max-width: 600px;
    border-radius: 25px;
    overflow: hidden;
    margin: 10px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
}

.Course_cardIn {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.course-card img {
    width: 100%;
    height: 200px;
    max-height: 200px;
    object-fit: cover;
    border-radius: 25px;
}

.course-info {
    flex: 1 1;
    box-sizing: border-box;
    padding: 20px;
}

.image-column {
    margin: 10px;
    flex: 1 1;
    display: flex;
    align-items: center;
}

.dropdown-toggle.btn.btn-secondary {
    background: none;
    border: none;
    color: black;
}

.dropdown-toggle::after {
    display: none !important;
}

@media (max-width: 768px) {
    .course-card img {
        max-height: 150px;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/ManageAdminCourse.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;IAClD,oCAAoC;AACxC;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,wCAAwC;IACxC,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,SAAO;IACP,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,SAAO;IACP,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["body {\n    font-family: \"Nanum Gothic\", sans-serif !important;\n    background-color: #F5FCF8 !important;\n}\n\n.course-card {\n    width: 100%;\n    max-width: 600px;\n    border-radius: 25px;\n    overflow: hidden;\n    margin: 10px auto;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    background-color: #FFFFFF;\n}\n\n.Course_cardIn {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\n.course-card img {\n    width: 100%;\n    height: 200px;\n    max-height: 200px;\n    object-fit: cover;\n    border-radius: 25px;\n}\n\n.course-info {\n    flex: 1;\n    box-sizing: border-box;\n    padding: 20px;\n}\n\n.image-column {\n    margin: 10px;\n    flex: 1;\n    display: flex;\n    align-items: center;\n}\n\n.dropdown-toggle.btn.btn-secondary {\n    background: none;\n    border: none;\n    color: black;\n}\n\n.dropdown-toggle::after {\n    display: none !important;\n}\n\n@media (max-width: 768px) {\n    .course-card img {\n        max-height: 150px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
