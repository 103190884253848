import React, { useState } from 'react';
import '../css/Chatbot.css';
import { BsChatDots, BsX } from 'react-icons/bs'; 
import Draggable from 'react-draggable';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChatbot = () => setIsOpen(!isOpen);

  const backgroundStyle = {
    display: isOpen ? 'block' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the transparency here (0.5 for 50% transparency)
    zIndex: 999,
  };

  return (
    <div>
      <div style={backgroundStyle} onClick={toggleChatbot}></div>
      <div className="chatbot-container">
        {!isOpen && (
          <button className="chatbot-toggle" onClick={toggleChatbot}>
            <BsChatDots /> {/* Chat icon */}
          </button>
        )}
        {isOpen && (
          <Draggable handle=".chatbot-header">
            <div
              className="chatbot-popup"
              style={{
                resize: "both",
                overflow: "auto",
                width: "360px",
                height: "600px",
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
              }}
            >
              <div className="chatbot-header">
                <span>Chatbot</span>
                <button className="chatbot-close" onClick={toggleChatbot} style={{ background: "none", border: "none", color: "#fff", cursor: "pointer" }}>
                  <BsX />
                </button>
              </div>
              <iframe
                src={process.env.REACT_APP_CHATBOT_URL}
                title="chatbot"
                width="100%"
                height="550px"
                frameBorder="0"
              ></iframe>
              <div className="d-flex justify-content-between terms-privacy">
                <a
                  href="/terms-and-conditions"
                  className="terms-privacy-link"
                >
                  Terms of Service
                </a>
                <a
                  href="/privacy"
                  className="terms-privacy-link"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </Draggable>
        )}
      </div>
    </div>
  );
};

export default Chatbot;
