import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = async () => {
  try {
    googleAuthProvider.setCustomParameters({ prompt: 'select_account' });
    const result = await auth.signInWithPopup(googleAuthProvider);
    const user = result.user;
    return user;
  } catch (error) {
    console.error("Error signing in with Google", error.message);
    throw error;
  }
};

export const resetPassword = async (email) => {
  try {
    // console.log(firebaseConfig);
    // const actionCodeSettings = {
    //   url: process.env.REACT_APP_API_URL_FORGET_PASSWORD,
    //   handleCodeInApp: true
    // };
    // console.log('Sending password reset email to:', email, process.env.REACT_APP_API_URL_FORGET_PASSWORD);
    // console.log('Action code settings:', actionCodeSettings);
    await auth.sendPasswordResetEmail(email);
    console.log('Password reset email sent successfully');
  } catch (error) {
    console.error('Error sending password reset email:', error.message);
    throw error;
  }
};

export default firebase;