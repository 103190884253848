import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import apiService from "../../services/api.service";
import UserHeader from "../User/UserHeader";
import "../../css/AdminCoursePreview.css";
import "../../css/CourseLibrary.css";
import '../../css/cart.css';
import { Container, Row, Col, Card, Table, Button } from "react-bootstrap";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loadStripe } from '@stripe/stripe-js';



const Cart = () => {
const stripePromise = loadStripe(`${process.env.REACT_APP_PUBLISHER_KEY}`);
  const [cartItems, setCartItems] = useState([]);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const navigate = useNavigate();
  const [userId, setUserId] = useState(localStorage?.getItem("userId"));
  const [cartCount, setCartCount] = useState(0);
  const [productDescription, setProductDescription] = useState(""); 
  const [email, setEmail] = useState(localStorage?.getItem("email"));
  const [username, setUsername] = useState("");
  const [flowLeaderShip, setFlowLeaderShip] = useState(false)
  const [inventoryLeadership, setInventoryLeadership] = useState(false)
  const [osLeadership, setOsLeadership] = useState(false)

  const handleClickBuy = async () => {
    try {
      if(cartItems.length <= 0){
        Swal.fire("Cart is empty");
        return;
      }
      const titles = cartItems.map((item) => item.courseTitle);
      const description = titles.join(", ");
      setProductDescription(description);
      const response = await apiService.createPaymentIntent(
        totalAfterDiscount.toFixed(),
        "USD",
        description,
        username,
        userId,
        cartItems
      );
      const { client_secret } = response.data;
      const stripe = await stripePromise;
      const result = stripe.redirectToCheckout({
        sessionId: client_secret,
      });
    } catch (error) {
      console.error("Error creating payment intent:", error);
    }
  };

  useEffect(() => {
    const extractUsername = (email) => {
      const atIndex = email?.indexOf("@");
      if (atIndex !== -1) {
        console.log("email::")
        const username = email?.substring(0, atIndex);
        return username;
      }
      return "";
    };
    if (email) {
      setUsername(extractUsername(email));
    }
    const fetchData = async () => {
      try {
        const response = await apiService.getAllCourses();
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchData();
  }, [email]);

  useEffect(() => {
    const fetchPurchasedCourses = async () => {
      try {
        const response = await apiService.getPurchasedCourses(userId);
        if (response && response.data) {
          setPurchasedCourses(response.data);
        }
      } catch (error) {
        console.error("Error fetching purchased courses:", error);
      }
    };

    fetchPurchasedCourses();
  }, [userId]);

  useEffect(() => {
    fetchCartItems();
  }, [userId, purchasedCourses]);

  const fetchCartItems = async () => {
    try {
      const response = await apiService.getCart(userId);
      const cartItems = response?.data?.items || [];
      const certificateEligibility = await apiService.certificateEligibility(userId)
      setFlowLeaderShip(certificateEligibility.data.FLOW_LEADERSHIP)
      setInventoryLeadership(certificateEligibility.data.INVENTORY_LEADERSHIP)
      setOsLeadership(certificateEligibility.data.OPERATIONS_SCIENCE_LEADERSHIP)
      setCartItems(cartItems);
      setCartCount(cartItems.length);
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

const [totalAfterDiscount, setTotalAfterDiscount] = useState(0); 
const [discount, setDiscount] = useState(0)
const [totalPrice, setTotalPrice] = useState(0)

useEffect(() => {
  if(cartItems){
    const totalPrice = cartItems.reduce((total, item) => total + item.coursePrice, 0);
    setTotalPrice(totalPrice)
    if (cartItems.length >= 3 && cartItems.length < 4) {
      setDiscount(totalPrice * 0.1)
    } else if (cartItems.length >= 4 && cartItems.length < 5) {
      setDiscount(totalPrice * 0.2)
    } else if (cartItems.length >= 5) {
      setDiscount(totalPrice * 0.3)
    } else {
      setDiscount(0)
    }
    setTotalAfterDiscount(totalPrice - discount);
  }
}, [cartItems, discount]);

  const handleRemoveItem = async (courseId) => {
    const confirmed = await Swal.fire({
      title: "Are you sure you want to remove the item from your cart?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
    });

    if (confirmed.isConfirmed) {
      try {
        await apiService.removeFromCart(userId, courseId);
        const response = await apiService.getCart(userId);
        const cartItems = response?.data?.items || [];
        const filteredCartItems = cartItems.filter(
          (cartItem) =>
            !purchasedCourses.find(
              (purchasedCourse) =>
                purchasedCourse.courseId === cartItem.courseId
            )
        );
        setCartItems(filteredCartItems);
        setCartCount(filteredCartItems.length);
        fetchCartItems();
        Swal.fire({
          title: "Deleted!",
          text: "Your course has been deleted.",
          icon: "success",
          customClass: {
            content: "text-center",
          },
          padding: "6rem",
        });
      } catch (error) {
        console.error("Error removing item from cart:", error);
        Swal.fire("Error", "Failed to delete item from cart.", "error");
      }
    }
  };

  const handleBuyAll = async () => {
    try {
      await Promise.all(
        cartItems.map(async (item) => {
          const { courseId, version } = item;
          await apiService.purchaseCourse(userId, courseId, version);
        })
      );
      Swal.fire({
        icon: "success",
        title: "Courses purchased successfully",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          title: "text-center",
          content: "text-center",
          confirmButton: "text-center",
        },
        padding: "3rem",
      });
      await apiService.clearCart(userId);
      setCartItems([]);
      setCartCount(0);
    } catch (error) {
      console.error("Error purchasing courses:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to purchase courses",
      });
    }
  };

  const handleClearCart = async () => {
    const confirmed = await Swal.fire({
      title: "Are you sure you want to clear your cart?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
    });

    if (confirmed.isConfirmed) {
      try {
        await apiService.clearCart(userId);
        setCartItems([]);
        setCartCount(0);
        fetchCartItems();
        Swal.fire({
          title: "Cleared!",
          text: "Your cart has been cleared.",
          icon: "success",
          customClass: {
            content: "text-center",
          },
          padding: "2rem",
        });
      } catch (error) {
        console.error("Error clearing cart:", error);
        Swal.fire("Error", "Failed to clear cart.", "error");
      }
    }
  };

  const handlenavigateManageCourse = () => {
    navigate(`/UserCourseSelection`);
  };

  return (
    <div>
      <UserHeader className="mb-4" cartCount={cartCount} />
      <br />
      <Container>
        <br />
        <Row>
          <Col sm={10}>
            <h3>CART</h3>
          </Col>
          <Col sm={2}>
            <Button onClick={handlenavigateManageCourse}>
              COURSE SELECTION
            </Button>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={6}>
            {cartItems.length === 0 ? (
              <Card>
                <Card.Body>
                  <p>None selected</p>
                </Card.Body>
              </Card>
            ) : (
              <Card>
                <Card.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>
                          <h6>COURSES IN CART</h6>
                        </th>
                        <th>
                          <h6>PRICE</h6>
                        </th>
                        <th>
                          <h6></h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((item, index) => (
                        <tr key={item.courseId}>
                          <td className="course-title-cart">
                            {item.courseTitle}
                          </td>
                          <td>${item.coursePrice.toLocaleString()}</td>
                          <td>
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              onClick={() => handleRemoveItem(item.courseId)}
                              className="delete-icon"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            )}
          </Col>
          <Col sm={6}>
            <span>
              The table below indicates which certificates are eligible for
              completion based on courses owned combined with courses selected
              for purchase.
            </span>
            <Card className="mt-4">
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        {" "}
                        <h6>CERTIFICATE</h6>
                      </th>
                      <th className="d-flex justify-content-center">
                        {" "}
                        <h6>ELIGIBLE?</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>FLOW LEADERSHIP</td>
                      <td className="d-flex justify-content-center">
                        {flowLeaderShip ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>INVENTORY LEADERSHIP</td>
                      <td className="d-flex justify-content-center">
                        {inventoryLeadership ? "Yes" : "No"}
                      </td>
                    </tr>
                    {/* <tr>
                      <td>OPERATIONS SCIENCE LEADERSHIP</td>
                      <td className="d-flex justify-content-center">{osLeadership ? "Yes" : "No"}</td>
                    </tr> */}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Col className="justify-content-center">
          <Col sm={6}>
            <Card className="mt-4">
              <Card.Body>
                <h5 className="card-title text-center">ORDER SUMMARY</h5>
                <Table>
                  <tbody>
                    <tr>
                      <td className="text-left">COURSE SUB-TOTAL</td>
                      <td className="text-right">
                        ${totalPrice.toLocaleString()}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">DISCOUNT</td>
                      <td className="text-right">
                        (${Math.round(discount).toLocaleString()})
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">TOTAL</td>
                      <td className="text-right">
                        ${Math.round(totalAfterDiscount).toLocaleString()}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <div className="m-2">
              By clicking the ”ACCEPT AND PLACE YOUR ORDER" button below, you
              acknowledge that you have read, understood, and agree to be bound
              by the <a href="/terms-and-conditions">Terms of Service</a> and{" "}
              <a href="/privacy">Privacy Policy</a> of our website. You also
              agree to abide by all the rules and regulations governing the use
              of our online courses. Your purchase and use of our courses are
              subject to these terms, and by proceeding, you consent to these
              conditions. If you do not agree to these terms, please do not
              complete your purchase.
            </div>
          </Col>
          <Row className="mt-4 mb-4 justify-content-center w-50">
            <Col xs="auto">
              <Button onClick={handleClickBuy}>
                ACCEPT AND PLACE YOUR ORDER
              </Button>
            </Col>
          </Row>
        </Col>
      </Container>
    </div>
  );
};

export default Cart;
