import React, { useEffect, useState , useRef} from "react";
import { useParams } from "react-router-dom";
import apiService from "../../services/api.service";
import { useNavigate } from "react-router-dom";
import UserHeader from "../User/UserHeader";
import "../../css/AdminCoursePreview.css";
import "../../css/UserCoursePreview.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { BiCheckCircle } from "react-icons/bi";


import {
  faLock,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import "../../css/global.css";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Table,
  Modal
} from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Vimeo from "@u-wave/react-vimeo";

const UserCoursePreview = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [videoDurations, setVideoDurations] = useState({});
  const [purchased, setPurchased] = useState(false);
  const navigate = useNavigate();
  const [userId, setUserId] = useState(localStorage?.getItem("userId"));
  const [cartCount, setCartCount] = useState(0);
  const [evaluationModal, setEvaluationModal] = useState(false);
  const [answers, setAnswers] = useState({});
  const [color, setColor] = useState({})
  const [answerSubmited, setAnswerSubmited] = useState(false)
  const [competedLesson, setCompetedLesson] = useState()
  const [evalutionButtonClick, setevalutionButtonClick] = useState(true)
  const [videoDescriptions, setVideoDescriptions] = useState({});
  const [showFileModal, setShowFileModal] = useState(false)
  const [fileDatas, setfileDatas] = useState([])

  useEffect(() => {
    const fetchCartCount = async () => {
      try {
        const response = await apiService.getCart(userId);
        if (response && response?.data?.items) {
          setCartCount(response?.data?.items.length);
        }
      } catch (error) {
        console.error("Error fetching cart count:", error);
      }
    };

    fetchCartCount();
  }, [userId]);

  const getCourse=() =>{
    apiService.getCourseById(courseId).then((response) => {
      setCourse(response?.data.course);
      if (response?.data.course && response.data.course.coursePrice === 0) {
        setPurchased(true);
      }
    });
  }
  
  useEffect(() => {
    getCourse();
    apiService
      .getPurchasedCourses(userId)
      .then((response) => {
        if (response && response.data) {
          for(let i=0; i<response.data.length; i++) {
            if(response.data[i].courseId === courseId){
              setCompetedLesson(response.data[i].completedLesson)
            }
          }
          const purchasedCourses = response.data;
          const isPurchased = purchasedCourses.some(
            (course) => course.courseId === courseId
          );
          const purchasedCourse = purchasedCourses.find(
            (course) => course.courseId === courseId
          );
          if (
            isPurchased &&
            purchasedCourse &&
            purchasedCourse.expirationDate
          ) {
            const today = new Date();
            const expirationDate = new Date(
              purchasedCourse.expirationDate._seconds * 1000
            );
            if (expirationDate <= today) {
              setPurchased(false);
            } else {
              setPurchased(true);
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching purchased courses:", error);
      });
  }, [courseId, userId]); 

  useEffect(() => {
    if(course){
      const fetchDescriptions = async () => {
        const promises = course.lessons.map(async (lesson) => {
          if (lesson.video) {
            const vimeoId = extractVimeoId(lesson.video);
            const response = await fetch(`${process.env.REACT_APP_VIMEO_TIME_FETCH_API}${vimeoId}`);
            const data = await response.json();
            return { [vimeoId]: data.duration }; 
          } else {
            return {};
          }
        });
    
        const durations = await Promise.all(promises);
        setVideoDescriptions(Object.assign({}, ...durations));
      };
    
      fetchDescriptions();
    }
  }, [course]);

  const watchedVideoCompletedly = (index) =>{
    if(index >= competedLesson){
      apiService.lessonCompleted(userId, courseId).then((response)=>{
        setCompetedLesson(response.data.completedLesson)
      })
    }
  } 


  const extractVimeoId = (videoUrl)=>{
    const videoId = videoUrl.split("?")[0]; 
    return videoId;
  }

  if (!course) {
    return <div>Loading...</div>;
  }

  const handlenavigateManageCourse = () => {
    navigate(`/CourseLibrary`);
  };
  const handlenavigateManageCourseselection = () => {
    navigate(`/UserCourseSelection`);
  };

const handleEvaluationClick = () => {
  setEvaluationModal(true);
};

const handleCloseEvaluationModal = () => {
  setEvaluationModal(false);
};

function isSimilarAnswer(answer, correctAnswer) {
  const lowerCaseAnswer = answer.toLowerCase();
  const lowerCaseCorrectAnswer = correctAnswer.toLowerCase();

  if (lowerCaseAnswer === lowerCaseCorrectAnswer) {
    return true;
  }

  // Split the string by 'and' to separate the parts
  const lowerCaseAnswerParts = lowerCaseAnswer.split(" and ");

  // Join the parts with a comma
  const resultLowerCaseAnswer = lowerCaseAnswerParts.join(", ");

  // Split the string by 'and' to separate the parts
  const lowerCaseCorrectAnswerParts = lowerCaseCorrectAnswer.split(" and ");

  // Join the parts with a comma
  const resultlowerCaseCorrectAnswer = lowerCaseCorrectAnswerParts.join(", ");

  const answerParts = resultLowerCaseAnswer
    .split(/([,.-]+)/)
    .map((part) => part.trim());
  const correctParts = resultlowerCaseCorrectAnswer
    .split(/([,.-]+)/)
    .map((part) => part.trim());

  if (answerParts.length !== correctParts.length) {
    return false; 
  }

  const areEqualLength = answerParts.length === correctParts.length;
  const sortedArray1 = answerParts.slice().sort();
  const sortedArray2 = correctParts.slice().sort();

  const areEqual = JSON.stringify(sortedArray1) === JSON.stringify(sortedArray2);

  if (areEqual) {
    return true;
  } else {
    return false;
  }
}

const handleEvaluationSubmit =async () => {
  let answerLength = Object.keys(answers).length;
  const evaluations = course.evaluations;
  if (answerLength < evaluations.length) {
    toast(`All answers are mandatory`);
    return;
  }
  let scoreObtained = 0;
  for (let i = 0; i < evaluations.length; i++) {
    let correctAnswer = "";
    if (evaluations[i].type === "multiple-options") {
      const data = answers[i];
      const keysToSave = Object.keys(data).filter((key) => data[key]);
      const result = keysToSave.join(",");
      answers[i] = result
    }
    if (evaluations[i].answer !== undefined) {
      correctAnswer = evaluations[i].answer;
    } else if (evaluations[i].correctOption !== undefined) {
      correctAnswer = evaluations[i].correctOption;
    }
    if (
      answers[i] === evaluations[i].answer ||
      answers[i] === evaluations[i].correctOption ||   
      isSimilarAnswer(answers[i], correctAnswer)
    ) {
      setColor((prevColor) => ({
        ...prevColor,
        [i]: true,
      }));
      scoreObtained = scoreObtained + 1;
    } else {
      setColor((prevColor) => ({
        ...prevColor,
        [i]: false,
      }));
    }
  }
  setAnswerSubmited(true);

  const response = await apiService.submitEvaluation(userId, scoreObtained, courseId)
  setevalutionButtonClick(false)
  if(scoreObtained===answerLength){
    // call api to evaluation completed
    await apiService.evaluationCompleted(userId, courseId)
  }
  
    if(scoreObtained===answerLength){
      Swal.fire({
        html: `
           <div style="font-size: 30px; text-align:center">
             Congratulations, you have completed the course successfully! Good work advancing your operations science leadership journey.
           </div>
           `,
        icon: "success",
        confirmButtonColor: "#28a745",
        confirmButtonText: "OK",
      }).then(() => {
        navigate(`/UserMyCourse`);
      });
    } 
    else {
      Swal.fire({
        html: `
           <div style="font-size: 30px; text-align:center">
             Good work, here are your results.<br>
             ${scoreObtained} Correct<br>
             ${answerLength - scoreObtained} Incorrect<br>
             Give it another try.
           </div>
           `,
        confirmButtonColor: '#28a745', 
        confirmButtonText: 'OK',
      });
    }
    // setColor({});
    setAnswerSubmited(false);
    handleCloseEvaluationModal();
    setAnswers({});
    setevalutionButtonClick(true)

};

const handleChange = (questionIndex, answer) => {
  setAnswers((prevAnswers) => ({
    ...prevAnswers,
    [questionIndex]: answer,
  }));
};

const handleChangeMultiple = (questionIndex, answer) => {
  setAnswers((prevAnswers) => ({
    ...prevAnswers,
    [questionIndex]: {
      ...prevAnswers[questionIndex],
      [answer]: !prevAnswers[questionIndex]?.[answer],
    },
  }));
}

function secondsToTime(seconds) {
  if (isNaN(seconds) || seconds < 0) {
    return "Invalid input";
  }

  const minutes = Math.floor(seconds / 60);

  return `${minutes} Minutes`;
}


const downloadAssets = (file) => {  
  window.open(file.url, '_blank');
}

const modalOpenFile = (files, filesData)=>{
  if(files > 0){
    setfileDatas(filesData)
    setShowFileModal(true)
  }else {
    Swal.fire("Assets have not been included.");
  }
}

const formatToTwoSignificantFigures = (value) => {
  const num = parseFloat(value);
  return num.toFixed(2);
};

  return (
    <div className="CoursePreview">
      <UserHeader className="mb-4" cartCount={cartCount} />
      <Container>
        <Row className="mt-4">
          <Col xs={12} md={8}>
            <h3>Course Preview</h3>
          </Col>
          <Col xs={12} md={4} className="d-flex justify-content-md-end mt-2 mt-md-0">
            <Button className="ml-md-2 mt-2 mt-md-0" onClick={handlenavigateManageCourse}>COURSE LIBRARY</Button>
            <Button
              style={{ marginLeft: "20px" }}
              className="ml-md-2 mt-2 mt-md-0"
              onClick={handlenavigateManageCourseselection}
            >
              COURSE SELECTION
            </Button>
          </Col>
        </Row>
      </Container>
      <div className="CourseDetail-bg">
        <Container>
          <Row className="mt-3">
            <Col sm={4} className="mt-4">
              <img
                src={course.thumbnailUrl}
                alt={course.courseTitle}
                style={{
                  width: "60%",
                  height: "auto",
                  marginBottom: "20px",
                }}
                className="user-preview-thumbnail"
              />
            </Col>
            <Col sm={8} className="course-details-right mt-4">
              <h2>{course.courseTitle}</h2>
              <p className="course-description">
                DESCRIPTION: {course.courseDescription}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="mt-4">
          <Col sm={12}>
            <Row>
              <Col sm={6}>
                <h2>LESSONS IN THIS COURSE</h2>
              </Col>
              <Col sm={6} className="d-flex justify-content-end">
                <h4>{course.lessons.length} LESSONS</h4>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="d-flex">
                <span className="lesson-instruction">
                  Each lesson must be completed in sequence. Once a lesson is
                  completed, the next lesson will be unlocked and available for
                  viewing.
                </span>
              </Col>
            </Row>
            <Card className="mt-4">
              <Card.Body>
                <Table striped bordered responsive="sm">
                  <thead>
                    <tr>
                      <th><h6>VIDEO</h6></th>
                      <th><h6>TITLE</h6></th>
                      <th><h6>DURATION</h6></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {course &&
                      course?.lessons &&
                      course?.lessons?.map((lesson, index) => (
                        <tr key={index}>
                          <td>
                            {lesson.video ? (
                              <div
                                className={`video-container ${
                                  !purchased ? "video-disabled" : ""
                                }`}
                                style={{ maxWidth: '100%' }}
                              >
                                {index <= competedLesson ? (
                                  index <= competedLesson - 1 ? (
                                    <Vimeo
                                      video={extractVimeoId(lesson.video)}
                                      onEnd={() =>
                                        watchedVideoCompletedly(index)
                                      }
                                      width={400}
                                      height={300}
                                      volume={1}
                                    />
                                  ) : (
                                    <Vimeo
                                      video={extractVimeoId(lesson.video)}
                                      onEnd={() =>
                                        watchedVideoCompletedly(index)
                                      }
                                      width={400}
                                      height={300}
                                      volume={1}
                                      // controls={false}
                                      className="custom-vimeo-player"
                                    />
                                  )
                                ) : (
                                  <p className="unlock-video-msg">
                                    Complete all previous lessons <br /> to
                                    unlock this one.
                                  </p>
                                )}
                              </div>
                            ) : (
                              "Not uploaded"
                            )}
                          </td>
                          <td>
                            <div className="d-flex gap-2 align-items-center ">
                              <span>{lesson.title} </span>
                              {index <= competedLesson ? (
                                <div className="d-flex gap-2 ">
                                  <FontAwesomeIcon
                                    icon={faUnlock}
                                    className="unlock-icon"
                                  />
                                  {index <= competedLesson - 1 ? (
                                    <BiCheckCircle />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ) : (
                                <FontAwesomeIcon
                                  icon={faLock}
                                  className="lock-iconTitle"
                                />
                              )}
                            </div>
                          </td>
                          <td>
                            <span>
                              {Object.keys(videoDescriptions).length !== 0
                                ? secondsToTime(
                                    videoDescriptions[
                                      lesson.video.match(/\d+/)[0]
                                    ]
                                  )
                                : `Calculating...`}
                            </span>
                          </td>
                          <td>
                            <Button
                              className={`${lesson.assetsArray.length > 0 ? ""  : "disabled-button-color"}`}
                              onClick={()=>modalOpenFile(lesson.assetsArray.length, lesson.assetsArray)}
                            >
                              FILES
                            </Button>
                            <Modal
                              show={showFileModal}
                              onHide={() => setShowFileModal(false)}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Files</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form className="file-dis-outer-preview">
                                  {
                                    fileDatas.map((file, index) => {
                                      return (
                                        <div className="d-flex gap-5 justify-content-between">
                                          <div key={index}>
                                            {file.filename}
                                          </div>
                                          <Button
                                           onClick={() => downloadAssets(fileDatas[index])}
                                          >
                                             Download
                                          </Button>
                                        </div>
                                      )
                                    })
                                  }
                                </Form>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={() => setShowFileModal(false)}
                                >
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <br />
      <Container>
        {
          <Container>
            {competedLesson > course.lessons.length - 1 && (
              <Button className="mb-3" onClick={handleEvaluationClick}>
                EVALUATION QUESTIONS
              </Button>
            )}
            <Modal show={evaluationModal} onHide={handleCloseEvaluationModal}>
              <Modal.Header closeButton>
                <Modal.Title>EVALUATION QUESTIONS</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                <Form>
                  {course.evaluations &&
                    course.evaluations.map((val, index) => {
                      if (val.type === "q&a") {
                        return (
                          <Form.Group
                            className={ index > 0 ? "mt-4" : ""}
                            controlId={`question${index + 1}`}
                            key={index}
                          >
                            <Form.Label>Question : {val.question}</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={`Enter answer for question ${
                                index + 1
                              }`}
                              onChange={(e) =>
                                handleChange(index, e.target.value)
                              }
                              // style={
                              //   answerSubmited
                              //     ? {
                              //         borderColor: color[index]
                              //           ? "green"
                              //           : "red",
                              //       }
                              //     : {}
                              // }
                            />
                          </Form.Group>
                        );
                      } else if (val.type === "options") {
                        return (
                          <Form.Group
                            className={ index > 0 ? "mt-4" : ""}
                            controlId={`question${index + 1}`}
                            key={index}
                          >
                            <Form.Label>Question : {val.question}</Form.Label>
                            {val.options &&
                              val.options.map((option, optionIndex) => (
                                <Form.Check
                                  key={optionIndex}
                                  type="radio"
                                  id={`option-${optionIndex}`}
                                  label={
                                    <span
                                      // style={
                                      //   answerSubmited
                                      //     ? {
                                      //         color:
                                      //           val?.correctOption?.includes(
                                      //             option
                                      //           )
                                      //             ? "green"
                                      //             : "red",
                                      //       }
                                      //     : {}
                                      // }
                                    >
                                      {option}
                                    </span>
                                  }
                                  name={`question${index}`}
                                  onChange={() => handleChange(index, option)}
                                  className="custom-radio"
                                />
                              ))}
                          </Form.Group>
                        );
                      } else if (val.type === "multiple-options") {
                        return (
                          <Form.Group
                            className={ index > 0 ? "mt-4" : ""}
                            controlId={`question${index + 1}`}
                            key={index}
                          >
                            <Form.Label>Question : {val.question}</Form.Label>
                            {val.options &&
                              val.options.map((option, optionIndex) => (
                                <Form.Check
                                  key={optionIndex}
                                  type="checkbox"
                                  id={`option-${optionIndex}`}
                                  label={
                                    <span
                                      // style={
                                      //   answerSubmited
                                      //     ? {
                                      //         color:
                                      //           val.correctOption === option
                                      //             ? "green"
                                      //             : "",
                                      //       }
                                      //     : {}
                                      // }
                                    >
                                      {option}
                                    </span>
                                  }
                                  name={`question${index}`}
                                  onChange={() =>
                                    handleChangeMultiple(index, option)
                                  }
                                  className="custom-radio"
                                />
                              ))}
                          </Form.Group>
                        );
                      }
                    })}
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleCloseEvaluationModal}
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={evalutionButtonClick ? handleEvaluationSubmit : null}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        }
      </Container>
      <div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
};

export default UserCoursePreview;