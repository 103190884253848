import React, { useState } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate, useParams } from 'react-router-dom';
import apiService from '../../services/api.service';
import CheckoutForm from "../User/checkoutform";
import UserHeader from "../User/UserHeader";
import { Container, Row, Col, Card, Table, Button } from "react-bootstrap";
const stripePromise = loadStripe(`${process.env.REACT_APP_PUBLISHER_KEY}`);

const StripePayment = () => {
  const { clientSecret } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    try {
      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            // Include any additional billing details as needed
          },
        },
      });

      if (error) {
        setMessage(error.message);
        setIsLoading(false);
      } else {
        setMessage('Payment successful!');
        setIsLoading(false);
        // Handle successful payment, e.g., navigate to a success page
        navigate('/payment-success');
      }
    } catch (error) {
      console.error('Error confirming payment:', error);
      setMessage('An unexpected error occurred.');
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
         <UserHeader className="mb-4" cartCount={cartCount} />
         <Container>
        <br />
         <Row>
          <Col sm={10}>
            <h3>PAYMENT</h3>
          </Col>
          <Col sm={2}>
         
          </Col>
        </Row>
        </Container>
         <div className='paymentStripe'>
      {clientSecret && (   
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
         </div>
   
    </div>
  );
};

export default StripePayment;