import React, { useState } from "react";
import { auth, firestore,resetPassword } from "../Common/firebase";
import { useNavigate } from "react-router-dom";
import "../css/signUp.css";
import Header from "../Common/Header";
import AdminHeader from "./Admin/AdminHeader";
import UserHeader from "./User/UserHeader";
import { GoogleLoginButton } from "react-social-login-buttons";
import { signInWithGoogle } from "../Common/firebase";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const customStyle = {
    width: "50%",
    backgroundColor: "#FFFFFF",
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleGoogleSignIn = async () => {
    try {
      const user = await signInWithGoogle();
      const { uid } = user;
      const userDoc = await firestore.collection("users").doc(user.uid).get();
      const userData = userDoc.data();
      
      if (userData && userData.isBlocked){
        Swal.fire({
          title: "User is blocked",
          text: "Please contact your administrator",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
          customClass: {
            title: "text-center",
            content: "text-center",
            confirmButton: "text-center",
          },
        })
      }
      else if (userData && userData.email === user.email) {
        localStorage.setItem("userRole", userData?.role);
        localStorage.setItem("userId", user.uid);
        localStorage.setItem("email", user.email);
        navigate("/Home");
      } else {
        Swal.fire({
          title: "User Not found",
          text: "Please Register to continue",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
          customClass: {
            title: "text-center",
            content: "text-center",
            confirmButton: "text-center",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/SignUp");
          }
        });
      }
    } catch (error) {
      setError("Error signing in with Google. Please try again.");
      console.error("Error signing in with Google", error.message);
    }
  };
  const handleSignIn = async () => {
    try {
      const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()])(?=.*\d).{8,}$/;

      if (!email && !password) {
        setError("Email and password cannot be empty.");
        return;
      }
      if (!email) {
        setError("Please enter your email.");
        return;
      }
  
      if (!password) {
        setError("Please enter your password.");
        return;
      }
  
      if (!isValidEmail(email)) {
        setError("Please enter a valid email address.");
        return;
      }
  
      const { user } = await auth.signInWithEmailAndPassword(email, password);
  
      if (user && user.emailVerified) {
        const userDoc = await firestore.collection("users").doc(user.uid).get();
        const userData = userDoc.data();
        if (userData.isBlocked){
          Swal.fire({
            title: "User is blocked",
            text: "Please contact your administrator",
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
            customClass: {
              title: "text-center",
              content: "text-center",
              confirmButton: "text-center",
            },
          })
        }else {
          localStorage.setItem("userRole", userData?.role);
          localStorage.setItem("userId", user.uid);
          localStorage.setItem("email", user.email);
    
          if (userData && userData.role === "admin") {
            navigate("/Admin");
          } else {
            navigate("/Home");
          }
        }
      } else {
        setError("Please verify your email address before logging in.");
      }
    } catch (error) {
      console.error("Error signing in", error.message);
      setError("Incorrect email or password.");
    }
  };
  
  
  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  
  const handleForgotPassword = async () => {
    let validEmail = false;
  
    const { value: email, dismiss: cancel } = await Swal.fire({
      title: 'Enter your email',
      input: 'email',
      inputLabel: 'Email address',
      inputPlaceholder: 'Enter your email address',
      showCancelButton: true,
      confirmButtonText: 'CONTINUE',
      cancelButtonText: 'CANCEL',
      showLoaderOnConfirm: false, 
      allowOutsideClick: false,
      preConfirm: async (email) => {
        try {
          const userQuery = await firestore.collection("users").where("email", "==", email).get();
          if (userQuery.empty) {
            throw new Error("Email address not found. Please enter a registered email.");
          }
          validEmail = true;
        } catch (error) {
          Swal.showValidationMessage(error.message);
        }
      }
    });
  
    if (email && !cancel && validEmail) {
      try {
        await resetPassword(email);
        Swal.fire({
          title: "Password Reset Email Sent",
          text: "Check your email for instructions to reset your password.",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
          customClass: {
            title: "text-center",
            content: "text-center",
            confirmButton: "text-center",
          },
        });
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Error sending password reset email. Please try again.",
          icon: "error",
          confirmButtonColor: "#d33",
          confirmButtonText: "OK",
          customClass: {
            title: "text-center",
            content: "text-center",
            confirmButton: "text-center",
          },
        });
      }
    }
  };
  
  return (
    <div className="Login">
     <AdminHeader className="mb-4" />
      <div
        style={{
          backgroundColor: "#F5FCF8",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <div className="card" style={{ width: "30rem" }}>
          <div className="center-align-container">
            <GoogleLoginButton
              className="GoogleSignup"
              onClick={handleGoogleSignIn}
            >
              <span className="GoogleSignupButton">SignIn with Google</span>
            </GoogleLoginButton>
          </div>
          <div className="center-align-container">
            <span className="Or-divider">OR</span>
          </div>
          <div className="center-align-container">
            <span className="Create_Account_Title">Login to your account</span>
          </div>
          <div className="card-body">
            <div className="form-group">
              <input
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="form-group password-input">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              />
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </div>
          <div className="center-align-container">
            <div className="forgot-password">
      <span onClick={handleForgotPassword}>Forgot Password?</span>
    </div>
          </div>
          <div className="center-align-container">
            <button onClick={handleSignIn} className="btn btn-primary">
              LOGIN TO CONTINUE
            </button>
          </div>
          <p className="Singup_Text">
            Don't have an account? <Link to="/SignUp">signup</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
