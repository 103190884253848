import React, { useState, useEffect } from "react";
import { auth, firestore, googleAuthProvider } from "../Common/firebase";
import "../css/signUp.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../Common/Header";
import AdminHeader from "./Admin/AdminHeader";
import { GoogleLoginButton } from "react-social-login-buttons";
import { Link } from "react-router-dom";
import apiService from "../services/api.service";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("User");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
  }, [role, navigate]);

  const handleGoogleSignIn = async () => {
    try {
      const result = await auth.signInWithPopup(googleAuthProvider);
      const user = result.user;
      const userEmail = user.email;
      const fullName = user.displayName;
      const userSnapshot = await firestore.collection("users").where("email", "==", userEmail).get();
  
      if (!userSnapshot.empty) {
        Swal.fire({
          title: "User already exist",
          text: "Duplicate email found. Create account using new email.",
          icon: "info",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
          customClass: {
            title: "text-center",
            content: "text-center",
            confirmButton: "text-center",
          },
        });
        return;
      }
      await firestore.collection("users").doc(user.uid).set({
        email: userEmail,
        role: "User",
        contactOption: "I will continue to follow the OSI. No need to contact me now",
        isBlocked: false
      });
      Swal.fire({
        title: "User Registered Successfully",
        text: "Please login to continue",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        customClass: {
          title: "text-center",
          content: "text-center",
          confirmButton: "text-center",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/Login");
        }
      });
    } catch (error) {
      console.error("Error signing in with Google", error.message);
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        customClass: {
          title: "text-center",
          content: "text-center",
          confirmButton: "text-center",
        },
      });
    }
  };
  

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()])(?=.*\d).{8,}$/;
    setEmailError("");
    setPasswordErrors([]);
  
    if (!email) {
      setEmailError("Please enter an email address");
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
    }
  
    if (!password) {
      setPasswordErrors(["Please enter a password"]);
    }else if (!passwordPattern.test(password)){   
      setPasswordErrors([
        "Password requirements",
        "- at least 8 characters in length",
        "- at least one uppercase and one lowercase letter",
        "- use at least one special character !@#$%^&*()",
      ]);
      return;
    }
  
    setLoading(true);

    if ( emailError || passwordErrors.length > 0) {
      return;
    }
  
    try {
      const emailExists = await firestore.collection("users").where("email", "==", email).get();
  
      if (!emailExists.empty) {
        Swal.fire({
          title: "User already exist",
          text: "Duplicate email found. Create account using new email.",
          icon: "info",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
          customClass: {
            title: "text-center",
            content: "text-center",
            confirmButton: "text-center",
          },
        });
        setLoading(false); 
        return;
      }
      const { user } = await auth.createUserWithEmailAndPassword(email, password);
      await firestore.collection("users").doc(user.uid).set({
        email: email,
        role: role,
        contactOption: "I will continue to follow the OSI. No need to contact me now",
        isBlocked: false
      });
      await user.sendEmailVerification();
      Swal.fire({
        title: "Registered successfully",
        text: "A verification email has been sent. Please check your inbox (or spam folder) and click on the link in the email to complete your account registration.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        customClass: {
          title: "text-center",
          content: "text-center",
          confirmButton: "text-center",
        },
      })
    } catch (error) {
      console.error("Error signing up", error.message);
      if (error.code === 'auth/email-already-in-use') {
        Swal.fire({
          title: "Email Already in Use",
          text: "The email address is already in use by another account.",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
          customClass: {
            title: "text-center",
            content: "text-center",
            confirmButton: "text-center",
          },
        });
      }
    }
    finally {
      setLoading(false); 
    }
  };
  const handleEmailBlur = () => {
    if (!email) {
      setEmailError("Please enter an email address");
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordBlur = () => {
    if (!password) {
      setPasswordErrors(["Please enter a password"]);
    }
  };

  return (
    <div className="Registration">
      <AdminHeader className="mb-4" />
      <div
        style={{
          backgroundColor: "#F5FCF8",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <div className="card" style={{ width: "30rem" }}>
          <div className="center-align-container">
            <GoogleLoginButton
              className="GoogleSignup"
              onClick={handleGoogleSignIn}
            >
              <span className="GoogleSignupButton">Signup with Google</span>
            </GoogleLoginButton>
          </div>
          <div className="center-align-container">
            <span className="Or-divider">OR</span>
          </div>
          <div className="center-align-container">
            <span className="Create_Account_Title">Create Account</span>
          </div>
          <div className="center-align-container">
            <p className="Singup_Text">
            Enter email and password below.  <br /> Passwords are required to have at least:  <br />
              - 8 characters <br />
              - one uppercase and one lowercase letter <br />
              - one of the following characters !@#$%^&*()
            </p>
          </div>
          <div className="card-body">
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={handleEmailBlur}
                className="form-control"
              />
              <div className="validation-message">{emailError}</div>
            </div>
            <div className="form-group password-input">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={handlePasswordBlur}
                className="form-control"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              />
            </div>
            <div className="validation-message">
              {passwordErrors &&
                passwordErrors.map((error, index) => (
                  <div key={index}>{error}</div>
                ))}
            </div>
          </div>
          <div className="center-align-container">
            <button onClick={handleSignUp} className="btn btn-primary" disabled={loading}>
              CREATE ACCOUNT
            </button>
          </div>
          <p className="Singup_Text">
            Already have an account? <Link to="/Login">Login</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
