import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import '../css/signUpHeader.css';

const CustomNavbar = () => {
  const navbarStyle = {
    backgroundColor: '#5C9933',
    color: '#ffffff',
  };

  const rightLinksStyle = {
    marginLeft: "auto",
    paddingRight: "5%",
  };

  return (
    <Navbar collapseOnSelect expand="lg" style={navbarStyle} variant="dark">
      <Navbar.Brand className="mr-auto" style={{ marginLeft: '20px' }}>OPERATIONS SCIENCE INSTITUTE</Navbar.Brand>
      <Nav className="mr-auto" >
        <Nav.Link href="/Home" className="Home">HOME</Nav.Link>
      </Nav>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto" style={rightLinksStyle}>
          <Nav.Link href="/Login" className="Login ms-4">LOGINs</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
