import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import apiService from "../../services/api.service";
import UserHeader from "../User/UserHeader";
import "../../css/AdminCoursePreview.css";
import "../../css/CourseLibrary.css";
import "../../css/userSelection.css";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
} from "react-bootstrap";
import { loadStripe } from '@stripe/stripe-js';

const CourseSelection = () => {
  const stripePromise = loadStripe(`${process.env.REACT_APP_PUBLISHER_KEY}`);
  const navigate = useNavigate();
  const [userId, setUserId] = useState(localStorage?.getItem("userId"));
  const [courses, setCourses] = useState([]);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [certificates, setCertificates] = useState([
    { name: "FLOW LEADERSHIP", price: "$1,101" },
    { name: "INVENTORY LEADERSHIP", price: "$1,036" },
    // { name: "OPERATIONS SCIENCE LEADERSHIP", price: "$1,539" },
  ]);
  const [selectedCertificate, setSelectedCertificate] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [email, setEmail] = useState(localStorage?.getItem("email"));
  const [username, setUsername] = useState("");
  const [flowLeaderShipPrice, setFlowLeaderShipPrice] = useState(0)
  const [inventoryLeadershipPrice, setInventoryLeadershipPrice] = useState(0)
  const [operationScienceLeadershipPrice, setOperationScienceLeadershipPrice] = useState(0)
  const [selectedCertificates, setSelectedCertificates] = useState([])
  const [prices, setPrices] = useState([0, 0, 0]);

  useEffect(() => {
    const fetchPurchasedCourses = async () => {
      try {
        const response = await apiService.getPurchasedCourses(userId);
        if (response && response.data) {
          setPurchasedCourses(response.data);
        }
      } catch (error) {
        console.error("Error fetching purchased courses:", error);
      }
    };
    fetchPurchasedCourses();
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService.getAllCourses();
        if (response && response.courses) {
          const publishedCourses = response.courses.filter(
            (course) => course.courseStatus === "publish"
          );
          setCourses(publishedCourses);
  
          const handleCertificateChange2 = (certificateName, setPrice) => {
            const coursesForCertificate = publishedCourses.filter((course) =>
              course.certificates.includes(certificateName)
            );
            const filteredCourses = coursesForCertificate.filter(
              (course) =>
                !purchasedCourses.some((pCourse) => pCourse.courseId === course.id)
            );
            const selectedCourseIds = filteredCourses.map((course) => course.id);
            setSelectedCertificates(selectedCourseIds);
  
            const totalPrice = getTotalPriceCertificates(selectedCourseIds);
            const discountedPrice = getDiscountedPriceCertificates(totalPrice, selectedCourseIds.length);
            setPrice(discountedPrice ? discountedPrice : totalPrice);
          };
  
          handleCertificateChange2("FLOW LEADERSHIP", setFlowLeaderShipPrice);
          handleCertificateChange2("INVENTORY LEADERSHIP", setInventoryLeadershipPrice);
          handleCertificateChange2("OPERATIONS SCIENCE LEADERSHIP", setOperationScienceLeadershipPrice);
        } else {
          console.error("Invalid API response structure.");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchData();
  
    const extractUsername = (email) => {
      const atIndex = email?.indexOf("@");
      if (atIndex !== -1) {
        const username = email?.substring(0, atIndex);
        return username;
      }
      return "";
    };
    if (email) {
      setUsername(extractUsername(email));
    }
  }, [email, selectedCertificates]);

  useEffect(() => {
    console.log('Updating price');
    setPrices([
      flowLeaderShipPrice,
      inventoryLeadershipPrice,
      operationScienceLeadershipPrice,
    ]);
  }, [flowLeaderShipPrice, inventoryLeadershipPrice, operationScienceLeadershipPrice]);

  useEffect(() => {
    const fetchCartCount = async () => {
      try {
        const purchasedResponse = await apiService.getPurchasedCourses(userId);
        if (purchasedResponse && purchasedResponse.data) {
          setPurchasedCourses(purchasedResponse.data);
          const cartResponse = await apiService.getCart(userId);
          if (cartResponse && cartResponse.data && cartResponse.data.items) {
            const cartItems = cartResponse.data.items;
            const filteredCartItems = cartItems.filter(
              (cartItem) =>
                !purchasedResponse.data.find(
                  (purchasedCourse) =>
                    purchasedCourse.courseId === cartItem.courseId
                )
            );
            setCartCount(filteredCartItems.length);
            setCartItems(filteredCartItems);
          }
        }
      } catch (error) {
        console.error("Error fetching cart count:", error);
      }
    };

    fetchCartCount();
  }, [userId]);

  const handleCertificateChange = (certificateName) => {
    setSelectedCertificate(certificateName);
    const coursesForCertificate = courses.filter((course) =>
      course.certificates.includes(certificateName)
    );
    const filteredCourses = coursesForCertificate.filter(
      (course) =>
        !purchasedCourses.some(
          (pCourse) =>
            pCourse.courseId === course.id
        )
    );
    setSelectedCourses(filteredCourses.map((course) => course.id));
  };

  const handleCheckboxChange = (courseId, checked = true) => {
    const course = courses.find((course) => course.id === courseId);
    const prerequisiteCourses = course.prerequisites.split(",");
    if (
      purchasedCourses.some(
        (pCourse) =>
          pCourse.courseId === courseId
      )
    ) {
      if(checked){
        Swal.fire({
          icon: "error",
          title: "Course Already Purchased",
          text: "You have already purchased this course.",
        });
      }  
    } else {
      if(course.prerequisites !== "None" && checked){
          for(var i = 0; i < prerequisiteCourses.length;i++){
            const result = courses.find(item => item.courseTitle === prerequisiteCourses[i]);
            if(result){
              handleCheckboxChange(result.id, false)
            }
          }
      }

      if(!checked){
        setSelectedCourses((prevSelected) =>
          prevSelected.includes(courseId)
            ? prevSelected  
            : [...prevSelected, courseId]  
        );
      }else {
        setSelectedCourses((prevSelected) =>
          prevSelected.includes(courseId)
            ? prevSelected.filter((id) => id !== courseId)
            : [...prevSelected, courseId]
        );
      }
      
    }
  };


  const getTotalPriceCertificates = (courseIds) => {
    return courseIds.reduce((total, courseId) => {
      const course = courses.find((c) => c.id === courseId);
      return total + (course ? course.coursePrice : 0);
    }, 0);
  };
  
  const getDiscountedPriceCertificates = (totalPrice, numCourses) => {
    let discount = 0;
    if (numCourses >= 5) {
      discount = totalPrice * 0.3;
    } else if (numCourses === 4) {
      discount = totalPrice * 0.2;
    } else if (numCourses === 3) {
      discount = totalPrice * 0.1;
    }
    if (discount === 0) {
      return 0;
    }
    const discountedPrice = totalPrice - discount;
    return discountedPrice;
  };

  const handleCourseClick = (courseId) => {
    navigate(`/UserCoursePreview/${courseId}`);
  };

  const renderStatusButton = (courseId) => {
    // const isCourseSelected = selectedCourses.includes(courseId);
    const isCourseAddedToCart = cartItems.some(
      (item) => item.courseId === courseId
    );
    const isCoursePurchased = purchasedCourses.some(
      (course) => course.courseId === courseId
    );

    if (isCoursePurchased) {
      return (
        <Button className="CoursePurchased" variant="success" >
          PURCHASED
        </Button>
      );
    } else if (isCourseAddedToCart) {
      return (
        <Button className="CourseAddedToCart" variant="secondary">
          IN CART
        </Button>
      );
    }  else {
      return (
        <Button
          className="CourseNotPurchased"
          variant="primary"
        >
          SELECT
        </Button>
      );
    }
  };

  const handleAddToCart = async (courseId) => {
    try {
      await apiService.addToCart(userId, courseId);
      Swal.fire({
        icon: "success",
        title: "Course added to cart",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          title: "text-center",
          content: "text-center",
          confirmButton: "text-center",
        },
        padding: "3rem",
      });
      const response = await apiService.getCart(userId);
      if (response && response.data.items) {
        setCartCount(response.data.items.length);
        setSelectedCertificate("");
        setSelectedCourses([]);
        const fetchPurchasedCourses = async () => {
          try {
            const response = await apiService.getPurchasedCourses(userId);
            if (response && response.data) {
              setPurchasedCourses(response.data);
            }
          } catch (error) {
            console.error("Error fetching purchased courses:", error);
          }
        };

        fetchPurchasedCourses();
      }
    } catch (error) {
      console.error("Error adding course to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add course to cart",
      });
    }
  };

  const handleAllAddToCart = async () => {
    try {
      if (selectedCertificate) {
        const coursesToAdd = selectedCourses.filter((courseId) => {
          const course = courses.find((course) => course.id === courseId);
          return course && course.coursePrice > 0;
        });

        if (coursesToAdd.length === 0) {
          Swal.fire({
            icon: "info",
            title: "Course already purchased",
            text: "There are no courses available for purchase under the selected certificate.",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
          return;
        }
      }

      await Promise.all(
        selectedCourses.map(async (courseId) => {
          const course = courses.find((course) => course.id === courseId);
          const isPurchased = purchasedCourses.some(
            (pCourse) =>
              pCourse.courseId === courseId && pCourse.version >= course.version
          );

          if (course && course.coursePrice > 0 && !isPurchased) {
            await apiService.addToCart(userId, courseId, course.version);
          }
        })
      );

      Swal.fire({
        icon: "success",
        title: "Courses added to cart",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          title: "text-center",
          content: "text-center",
          confirmButton: "text-center",
        },
        padding: "3rem",
      });

      const response = await apiService.getCart(userId);
      if (response && response.data.items) {
        const cartItems = response?.data?.items || [];
        const filteredCartItems = cartItems.filter(
          (cartItem) =>
            !purchasedCourses.find(
              (purchasedCourse) =>
                purchasedCourse.courseId === cartItem.courseId
            )
        );
        setCartCount(filteredCartItems.length);
        setSelectedCertificate("");
        setSelectedCourses([]);
        // setCartCount(response.data.items.length);
        const fetchCartCount = async () => {
          try {
            const response = await apiService.getCart(userId);
            if (response && response?.data?.items) {
              const cartItems = response?.data?.items || [];
              const filteredCartItems = cartItems.filter(
                (cartItem) =>
                  !purchasedCourses.find(
                    (purchasedCourse) =>
                      purchasedCourse.courseId === cartItem.courseId
                  )
              );
              setCartCount(filteredCartItems.length);
              setCartItems(response?.data?.items);
            }
          } catch (error) {
            console.error("Error fetching cart count:", error);
          }
        };

        fetchCartCount();
        const fetchPurchasedCourses = async () => {
          try {
            const response = await apiService.getPurchasedCourses(userId);
            if (response && response.data) {
              setPurchasedCourses(response.data);
            }
          } catch (error) {
            console.error("Error fetching purchased courses:", error);
          }
        };

        fetchPurchasedCourses();
      }
    } catch (error) {
      console.error("Error adding courses to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add courses to cart",
      });
    }
  };

  const handleBuyAll = async () => {
    try {
      if (selectedCertificate) {
        const coursesToAdd = selectedCourses.filter((courseId) => {
          const course = courses.find((course) => course.id === courseId);
          return course && course.coursePrice > 0;
        });

        if (coursesToAdd.length === 0) {
          Swal.fire({
            icon: "info",
            title: "Course Already Purchased",
            text: "There are no courses available for purchase under the selected certificate.",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
          return;
        }
      }

      const cartItems = selectedCourses.map((courseId)=>{
        const course = courses.find((c) => c.id === courseId);
        if (course) {
          return {
            cartId: course.id,
            coursePrice: course.coursePrice,
            courseTitle: course.courseTitle,
            version: course.version,
            courseId: course.id
          };
        } else {
          return null;
        }
      }).filter(item => item !== null);

      const titles = cartItems.map((item) => item.courseTitle);
      const description = titles.join(", ");

      let totalPrice;
      if(getDiscountedPrice() > 0){
        totalPrice = getDiscountedPrice();
      }else{
        totalPrice = getTotalPrice();
      }
      const response = await apiService.createPaymentIntent(
        totalPrice.toFixed(),
        "USD",
        description,
        username,
        userId,
        cartItems
      );
      const { client_secret } = response.data;
      const stripe = await stripePromise;
      const result = stripe.redirectToCheckout({
        sessionId: client_secret,
      })
    } catch (error) {
      console.error("Error purchasing course:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to purchase courses",
      });
    }
  };

  const handleResetSelection = () => {
    setSelectedCertificate("");
    setSelectedCourses([]);
  };

  const getTotalPrice = () => {
    return selectedCourses.reduce((total, courseId) => {
      const course = courses.find((c) => c.id === courseId);
      return total + (course ? course.coursePrice : 0);
    }, 0);
  };

  const getDiscountedPrice = () => {
    const totalPrice = getTotalPrice()
    const numCourses = selectedCourses.length;
    let discount = 0;
    if (numCourses >= 5) {
      discount = totalPrice * 0.3;
    } else if (numCourses === 4) {
      discount = totalPrice * 0.2;
    } else if (numCourses === 3) {
      discount = totalPrice * 0.1;
    }
    if(discount === 0){
      return discount;
    }
    const discountedPrice = totalPrice - discount;
    return discountedPrice;
  };

  return (
    <div className="CoursePreview">
      <UserHeader className="mb-4" cartCount={cartCount} />
      <br />
      <Container>
        <br />
        <Row>
          <Col sm={10}>
            <h3>COURSE SELECTION</h3>
          </Col>
          <Col sm={2}>
            <Button onClick={() => navigate(`/CourseLibrary`)}>
              COURSE LIBRARY
            </Button>
          </Col>
          <div className="mt-4">
            <Card.Text className="mb-10-course-library">
              Online courses may be purchased as a certificate bundle or
              individually. Note that selection of an individual course will
              require purchase of the course's prerequisite courses.
            </Card.Text>
            <Card.Text className="mb-10-course-library">
              Description of discounts is provided at the bottom of this page.
            </Card.Text>
            <Card.Text className="mb-10-course-library">
              When your selection is made, total price and discounted pricing
              are displayed in a table at the bottom of the page.
            </Card.Text>
          </div>
        </Row>
        <Row className="mt-4">
          <Col sm={12}>
            <Card.Text className="mb-4">
              In the next table, you may select a certificate to automatically
              select its courses, If you have already purchased courses, the
              discounted certificate price shown in the table reflects the cost
              for the remaining courses needed to complete the certificate.
            </Card.Text>
            <Card>
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <h6>SELECT</h6>{" "}
                      </th>
                      <th>
                        <h6>CERTIFICATE</h6>
                      </th>
                      <th>
                        <h6 className="d-flex justify-content-center">
                          DISCOUNTED PRICE
                        </h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {certificates.map((certificate, index) => (
                      <tr
                        key={index}
                        onClick={() =>
                          handleCertificateChange(certificate.name)
                        }
                      >
                        <td>
                          <Form.Check
                            type="checkbox"
                            style={{ paddingLeft: "20px" }}
                            checked={selectedCertificate === certificate.name}
                            // onChange={() =>
                            //   handleCertificateChange(certificate.name)
                            // }
                            readOnly
                          />
                        </td>
                        <td>{certificate.name}</td>
                        <td className="d-flex justify-content-center">
                          <span> {Math.round(prices[index])} </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="text-center mt-3">
                  <Button
                    disabled={selectedCourses.length === 0}
                    onClick={handleResetSelection}
                  >
                    RESET SELECTION
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={12}>
            <Card.Text className="mb-4">
              Or, for a la carte pricing, select individual courses from the
              table below (prerequisites will be automatically included).{" "}
              <strong>Do not deselect prerequisites.</strong> If courses are
              purchased without their prerequisites, they will not be accessible
              until prerequisite courses are purchased and completed.
            </Card.Text>
            <Card>
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <h6>SELECT</h6>
                      </th>
                      <th>
                        <h6>COURSE NAME</h6>
                      </th>
                      <th>
                        <h6>PURCHASE STATUS</h6>
                      </th>
                      <th>
                        <h6>PRICE</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {courses.length === 0 ? (
                      <tr>
                        <td colSpan="6">NO COURSE AVAILABLE</td>
                      </tr>
                    ) : (
                      courses
                        .filter((course) => course?.coursePrice > 0)
                        .map((course) => (
                          <tr
                            key={course.id}
                            onClick={() => handleCheckboxChange(course.id)}
                          >
                            <td>
                              <Form.Check
                                className="cursor-pointer"
                                style={{ paddingLeft: "20px" }}
                                type="checkbox"
                                // onChange={() => handleCheckboxChange(course.id)}
                                checked={selectedCourses.includes(course.id)}
                              />
                            </td>
                            <td>{course.courseTitle.toUpperCase()}</td>
                            <td>{renderStatusButton(course.id)}</td>
                            <td>
                              <span style={{ paddingLeft: "9px" }}>
                                {course.coursePrice === 0
                                  ? "Free"
                                  : `$${course.coursePrice.toLocaleString()}`}
                              </span>
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row className="justify-content-end">
          <Col xs={12} sm={8} md={6} lg={4}>
            <Card className="mt-4">
              <Card.Body>
                <Table striped bordered hover className="price-table">
                  <thead>
                    <tr>
                      <th>SELECTION PRICE</th>
                      <th>DISCOUNTED PRICE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">
                        ${Math.round(getTotalPrice()).toLocaleString()}
                      </td>
                      <td className="text-center">
                        ${Math.round(getDiscountedPrice()).toLocaleString()}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <br />
            <Row>
              <Col className="d-flex justify-content-center">
                <Button
                  variant="primary"
                  className="mx-2 my-1"
                  onClick={handleAllAddToCart}
                >
                  ADD TO CART
                </Button>
                <Button
                  variant="primary"
                  onClick={() => navigate(`/cart`)}
                  className="mx-2 my-1"
                >
                  GO TO CART
                </Button>
              </Col>
            </Row>
            <br />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="mb-4">
            <Card className="mt-4">
              <Card.Body>
                <Card.Text>
                  Discounts are applied as follows:
                  <ul className="custom-list pl-zero">
                    <li>3 courses, 10% </li>
                    <li>
                      4 courses, 20% (Inventory Leadership certificate qualifies
                      for this discount)
                    </li>
                    <li>
                      5 or more courses, 30% (Purchasing Flow Leadership
                      certificate courses qualifies for this discount)
                    </li>
                  </ul>
                  Discounts apply only to individual transactions. For example,
                  if one course is purchased and, later, two are purchased,
                  there is no 10% discount for purchasing three courses.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
};

export default CourseSelection;
