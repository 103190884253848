import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import "../../css/AdminAddCourse.css";
import "../../css/global.css";
import AdminHeader from "./AdminHeader";
import Swal from "sweetalert2";
import apiService from "../../services/api.service";
import addImageIcon from "../../assets/add-image.png";
import addVideoIcon from "../../assets/add-video.png";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import uploadIcon from "../../assets/Upload_icon_pxxjvo.svg"


const AdminAddCourse = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [getcoursetitle, setgetcoursetitle] = useState(null);
  const [selectedPrerequisites, setSelectedPrerequisites] = useState([]);
  const fileInputRef = useRef(null);
  const [initialCertificatesState, setInitialCertificatesState] = useState({});
  const imageFileInputRef = useRef(null);
  const videoFileInputRef = useRef(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("basic-info");

  const [courseTitle, setCourseTitle] = useState("");
  const [lessonTitle, setLessonTitle] = useState("");
  const [lessonDescription, setLessonDescription] = useState("");
  const [courseDescription, setcourseDescription] = useState("");
  const [questions, setQuestions] = useState([{ question: "", answer: "" }]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageUrl, setimageUrl] = useState(null);
  const [videoUrl, SetVideoUrl] = useState(null);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [coursePrice, setCoursePrice] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [editableLesson, setEditableLesson] = useState(null);
  const [currentLessonIndex, setCurrentLessonIndex] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newVideoFile, setNewVideoFile] = useState(null);
  const [showEditModalEvaluation, setShowEditModalEvaluation] = useState(false);
  const [editedEvaluation, setEditedEvaluation] = useState(null);
  const [evaluations, setEvaluations] = useState([]);
  const [questionType, setQuestionType] = useState("q&a");
  const [questionText, setQuestionText] = useState("");
  const [answerText, setAnswerText] = useState("");
  const [options, setOptions] = useState([""]);
  const [correctOption, setCorrectOption] = useState([]);
  const [courseStatus, setCourseStatus] = useState("draft");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    uploadedFiles: [],
  });

  const [files, setFiles] = useState([]);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const handleFileSelect = (event) => {
    const files = event.target.files;
    const newFile = event.target.files[0];
    const id = uuidv4();
    const fileTypeWithoutDot = files[0]?.name.split(".").pop();
    files[0].id = `${id}.${fileTypeWithoutDot}`;
    newFile.id = `${id}.${fileTypeWithoutDot}`;
    handleFiles(files);
    setFiles((prevFiles) => [...prevFiles, newFile]);
  };

  const handleFiles = async (files) => {
    const newFiles = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
      fileId: file.id,
      type: file.type,
    }));
    setUploadedFiles([...uploadedFiles, ...newFiles]);
    setFormData({
      ...formData,
      uploadedFiles: [...uploadedFiles, ...newFiles],
    });
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setFormData({ ...formData, uploadedFiles: [updatedFiles] });
    setUploadedFiles(updatedFiles);
    setFiles(updatedFiles);
  };

  const [formDataUpdate, setFormDataUpdate] = useState({
    uploadedFiles: [],
  });
  
  const [filesUpdate, setFilesUpdate] = useState([]);

  const [uploadedFilesUpdate, setUploadedFilesUpdate] = useState([]);

  const handleDropUpdate = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFilesUpdate(files);
  };

  const handleFileSelectUpdate = (event) => {
    const files = event.target.files;
    if(files){
      const newFile = event.target.files[0];
      const id = uuidv4();
      const fileTypeWithoutDot = files[0]?.name.split(".").pop();
      files[0].id = `${id}.${fileTypeWithoutDot}`;
      newFile.id = `${id}.${fileTypeWithoutDot}`;
      handleFilesUpdate(files);
      setFilesUpdate((prevFiles) => [...prevFiles, newFile]);
    }
  };

  const handleFilesUpdate = async (files) => {
    const newFiles = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
      fileId: file.id,
      type: file.type,
    }));
    const updatedLessons = [...lessons];

    if(updatedLessons[currentLessonIndex].files){
      updatedLessons[currentLessonIndex].files.push(...files);
    }else if (updatedLessons[currentLessonIndex].assetsArray){
      updatedLessons[currentLessonIndex].assetsArray.push(...files);
    }else {
      updatedLessons[currentLessonIndex].files = [];
      updatedLessons[currentLessonIndex].files.push(...files);
    }

    setLessons(updatedLessons);

    setUploadedFilesUpdate([...uploadedFilesUpdate, ...newFiles]);
    setFormDataUpdate({
      ...formDataUpdate,
      uploadedFilesUpdate: [...uploadedFilesUpdate, ...newFiles],
    });
  };

  const handleRemoveFileUpdate = async (index) => {
    const confirmationResult = await Swal.fire({
      title: "Are you sure you want to delete the file ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
    if (confirmationResult.isConfirmed) {
      const updatedFiles = lessons[currentLessonIndex].files
        ? [...lessons[currentLessonIndex].files]
        : lessons[currentLessonIndex].assetsArray
        ? [...lessons[currentLessonIndex].assetsArray]
        : [];
      updatedFiles.splice(index, 1);

      const updatedLessons = [...lessons];

      if (lessons[currentLessonIndex].files) {
        updatedLessons[currentLessonIndex] = {
          ...updatedLessons[currentLessonIndex],
          files: updatedFiles,
        };
      } else if (lessons[currentLessonIndex].assetsArray) {
        updatedLessons[currentLessonIndex] = {
          ...updatedLessons[currentLessonIndex],
          assetsArray: updatedFiles,
        };
      }

      setLessons(updatedLessons);
      setEditableLesson(updatedLessons[currentLessonIndex])
    }
  };


  const handleStatusChange = (status) => {
    setCourseStatus(status);
  };


  const handlePrerequisitesChange = (event) => {
    const checkboxId = event.target.id;
    const isChecked = event.target.checked;
    if (checkboxId === "prerequisite-none") {
      setSelectedPrerequisites(
        isChecked ? [] : getcoursetitle?.map((course) => course.courseTitle)
      );
    } else {
      const checkboxValue = event.target.value;
      setSelectedPrerequisites((prevPrerequisites) => {
        if (!Array.isArray(prevPrerequisites)) {
          prevPrerequisites = [];
        }
        if (isChecked) {
          return [...prevPrerequisites, checkboxValue];
        } else {
          return prevPrerequisites.filter(
            (prerequisite) => prerequisite !== checkboxValue
          );
        }
      });
    }

    console.log(selectedPrerequisites);
    console.log(selectedPrerequisites.length);
  };

  const handleNext = () => {
    if (activeTab === "basic-info") {
      setActiveTab("lessons");
    } else if (activeTab === "lessons") {
      setActiveTab("evaluation");
    } else if (activeTab === "evaluation") {
      setActiveTab("course-status");
    }
  };

  const handlePrevious = () => {
    if (activeTab === "lessons") {
      setActiveTab("basic-info");
    } else if (activeTab === "evaluation") {
      setActiveTab("lessons");
    } else if (activeTab === "course-status") {
      setActiveTab("evaluation");
    }
  };
  useEffect(() => {
    apiService.getAllCourses().then((response) => {
      if (response && response.courses) {
        setgetcoursetitle(response.courses);
      } else {
        console.error("Invalid API response structure.");
      }
    });
    if (courseId) {
      apiService.getCourseById(courseId).then((response) => {
        setCourse(response?.data.course);
        setCourseTitle(response?.data.course.courseTitle);
        setcourseDescription(response?.data.course.courseDescription);
        setCoursePrice(response?.data.course.coursePrice);
        setLessons(response?.data.course.lessons);
        setEvaluations(response?.data.course.evaluations);
        setCourseStatus(response?.data.course.courseStatus);
        const certificatesFromApi = response?.data.course.certificates || [];
        setimageUrl(response?.data.course.thumbnailUrl);
        let certificatesArray;
        if (typeof certificatesFromApi === "string") {
          // Convert the string to an array (you can adjust the logic based on your data structure)
          certificatesArray = certificatesFromApi.split(",");
        } else if (Array.isArray(certificatesFromApi)) {
          // It's already an array
          certificatesArray = certificatesFromApi;
        } else {
          // If it's neither a string nor an array, handle the case accordingly
          certificatesArray = [];
        }
        setCertificates(certificatesArray);
        if(response?.data.course.prerequisites === "None") {
          setSelectedPrerequisites([])
        }else{
          setSelectedPrerequisites(response?.data.course.prerequisites || []);
        }
        const certificatesToCheck = [
          "FLOW LEADERSHIP",
          "INVENTORY LEADERSHIP",
          "OPERATIONS SCIENCE LEADERSHIP",
        ];
        const initialCertificatesState = certificatesToCheck.reduce(
          (state, certificate) => {
            state[certificate] = certificatesFromApi.includes(certificate);
            return state;
          },
          {}
        );

        setInitialCertificatesState(initialCertificatesState);
      });
    }
  }, [courseId]);

  const handleSave = () => {};
  const resetForm = () => {
    setCourseTitle("");
    setLessonTitle("");
    setLessonDescription("");
    setcourseDescription("");
    setCertificates([]);
    setUploadedImage(null);
    setimageUrl([]);
    setCoursePrice("");
    setCourseStatus("");
    setLessons([]);
    setEvaluations([]);
  };

  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will lose all unsaved changes.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset it!",
      customClass: {
        title: "text-center",
        content: "text-center",
        confirmButton: "text-center",
      },
      padding: "3rem",
    }).then((result) => {
      if (result.isConfirmed) {
        resetForm();
        Swal.fire("Reset!", "The form has been reset.", "success");
      }
    });
  };
  const handleAddOption = () => {
    if (options?.length < 6) {
      setOptions([...options, ""]);
    }
  };
  const handleDeleteOption = () => {
    const updatedOptions = [...options];
    updatedOptions.pop();
    setOptions(updatedOptions);
  };
  const handleAddEvaluation = () => {
    if (
      questionText.trim() === "" ||
      (questionType === "q&a" && answerText.trim() === "")
    ) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please fill all evaluation fields.",
        customClass: {
          title: "text-center",
          content: "text-center",
          confirmButton: "text-center",
        },
        padding: "3rem",
      });
      return;
    } 
        
    if(questionType != "q&a" && correctOption.length <= 0){
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Select an answer from the choices.",
        customClass: {
          title: "text-center",
          content: "text-center",
          confirmButton: "text-center",
        },
        padding: "3rem",
      });
      return
    }

    const newEvaluation = {
      id: evaluations.length + 1,
      type: questionType,
      question: questionText,
      correctOption: correctOption,
    };
        
    if (questionType === "q&a") {
      newEvaluation.answer = answerText;
    } else {
      newEvaluation.options = options;
    }
    
    setEvaluations([...evaluations, newEvaluation]);
    setQuestionText("");
    setAnswerText("");
    setOptions([""]);
    setCorrectOption([])
    setShowEditModalEvaluation(false);
  };

  const handleEditEvaluation = (evaluation) => {
    setEditedEvaluation(evaluation);
    setQuestionType(evaluation.type);
    setQuestionText(evaluation.question);
    if (evaluation.type === "q&a") {
      setAnswerText(evaluation.answer);
    } else {
      setOptions(evaluation.options);
      setCorrectOption(evaluation.correctOption);
    }
    setShowEditModalEvaluation(true);
  };

  const handleUpdateEvaluation = () => {
    const updatedEvaluations = evaluations?.map((evaluation) => {
      if (evaluation.question === editedEvaluation.question) {
        return {
          ...evaluation,
          type: questionType,
          question: questionText,
          correctOption: correctOption,
          ...(questionType === "q&a" ? { answer: answerText } : { options: options }),
        };
      }
      return evaluation;
    });

    setEvaluations(updatedEvaluations);
    setShowEditModalEvaluation(false);
    setQuestionType("");
    setQuestionText("");
    setAnswerText("");
  };

  const handleDeleteEvaluation = async (evaluationToDelete) => {
    const confirmationResult = await Swal.fire({
      title: "Are you sure you want to remove the evaluation ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
    if (confirmationResult.isConfirmed) {
      const updatedEvaluations = evaluations.filter(
        (evaluation) => evaluation !== evaluationToDelete
      );
      setEvaluations(updatedEvaluations);
    }
  };

  const handleOptionAnswerChange = (option) => {
    setCorrectOption([option]);
  };

  const handleMultipleOptions = (option) => {
     if (correctOption.includes(option)) {
      setCorrectOption((prevOptions) =>
        prevOptions.filter((item) => item !== option)
      );
    } else {
      setCorrectOption((prevOptions) => [...prevOptions, option]);
    }
  }

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleEditLesson = (lesson, index) => {
    setEditableLesson(lesson);
    setCurrentLessonIndex(index);
    setShowEditModal(true);
  };

  const handleUpdateLesson = (index) => {
    const lessonIndex = index
    const updatedLessons = [...lessons];
    const updatedLesson = { ...editableLesson };
    updatedLesson.title = editableLesson.title;
    updatedLesson.description = editableLesson.description;

    // if(filesUpdate.length > 0){
    //   updatedLesson.files = filesUpdate
    // }
    // console.log(filesUpdate);

    if(editableLesson.video){
      const url = getVimeoID(editableLesson.video);
      updatedLesson.video = url;
      updatedLessons[lessonIndex] = updatedLesson;
      setLessons(updatedLessons);
      setShowEditModal(false);
    }else {
      const url = getVimeoID(editableLesson.video);
      updatedLesson.video = url;
      updatedLessons[lessonIndex] = updatedLesson;
      setLessons(updatedLessons);
      setShowEditModal(false);
    }
  };

  const handleDeleteLesson =async (lessonToDelete) => {
    const confirmationResult = await Swal.fire({
      title: "Are you sure you want to remove the lesson ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
    if (confirmationResult.isConfirmed) {
      try {
        const updatedLessons = lessons.filter(
          (lesson) => lesson !== lessonToDelete
        );
        setLessons(updatedLessons);
      } catch (error) {
        console.error("Error during logout:", error.message);
      }
    }
    
  };

  const handleImageUpload = (acceptedFiles) => {
    const imageFile = acceptedFiles[0];
    setUploadedImage(imageFile);
    setimageUrl("");
  };
  const handleCertificateChange = (certificate, checked) => {
    if (checked) {
      setCertificates([...certificates, certificate]);
    } else {
      setCertificates(
        certificates && certificates.filter((c) => c !== certificate)
      );
    }
  };

  // Function to extract video ID
  const getVimeoID = (url) => {
    const videoUrl = url?.replace(/^https:\/\/vimeo.com\//, "");
    return videoUrl;
};

  const handleManageCourse = () => {
    navigate(`/AdminManageCourse`);
  };

  const handleAddLesson = () => {
    if (
      lessonTitle.trim() === "" ||
      lessonDescription.trim() === "" ||
      videoUrl === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please fill all lesson fields and upload a video.",
        customClass: {
          title: "text-center",
          content: "text-center",
          confirmButton: "text-center",
        },
        padding: "3rem",
      });
      return;
    }
    if(lessons.length > 15){
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Lesson Limit Exceeded: You can only add up to 15 lessons per course.",
        customClass: {
          title: "text-center",
          content: "text-center",
          confirmButton: "text-center",
        },
        padding: "3rem",
      });
      return;
    }
    const newLesson = {
      title: lessonTitle,
      description: lessonDescription,
      video: getVimeoID(videoUrl),
      files: files  
    };
    setLessons([...lessons, newLesson]);
    setLessonTitle("");
    setLessonDescription("");
    SetVideoUrl("");
    setUploadedVideo(null);
    setFiles([])
    setUploadedFiles([])
  };

  // const handleVideoUpload = (acceptedFiles) => {
  //   const videoFile = acceptedFiles[0];
  //   setUploadedVideo(videoFile);
  // };
  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    setNewVideoFile(file);
  };

  const handleSaveButtonClick = () => {
    try {
      setTimeout(async () => {
        setLoading(true);
        const formData = new FormData();
        const validationMessages = [];

        if (!courseTitle) {
          validationMessages.push("Course Title is mandatory.");
        } else {
          formData.append("courseTitle", courseTitle);
        }

        if (!courseDescription) {
          validationMessages.push("Course Description is mandatory.");
        } else {
          formData.append("courseDescription", courseDescription);
        }

        if (certificates.length === 0) {
          validationMessages.push("At least one Certificate is mandatory.");
        } else {
          formData.append("certificates", certificates);
          // certificates.forEach((certificate) => {
          //   formData.append("certificates", certificate);
          // });
        }
        const prerequisitesToAppend =
          selectedPrerequisites.length > 0 ? selectedPrerequisites : ["None"];
        if (selectedPrerequisites.length > 0) {
          formData.append("prerequisites", selectedPrerequisites);
        } else {
          formData.append("prerequisites", prerequisitesToAppend);
        }

        if (
          !uploadedImage &&
          (!Array.isArray(imageUrl) || imageUrl.every((url) => !url))
        ) {
          validationMessages.push("Thumbnail Image is mandatory.");
        } else {
          if (uploadedImage) {
            formData.append("thumbnail", uploadedImage);
          } else {
            formData.append("thumbnail", "");
          }
        }
        if (
          coursePrice === null ||
          coursePrice === undefined ||
          coursePrice === ""
        ) {
          validationMessages.push("Course Price is mandatory.");
        } else {
          formData.append("coursePrice", coursePrice);
        }
        formData.append("courseStatus", courseStatus);

        for (const pair of formData.entries()) {
          // console.log(pair[0] + ", " + pair[1]);
        }
        const lessonsValidationMessages = [];

        if (lessons.length === 0) {
          lessonsValidationMessages.push("Please add at least 1 lesson.");
        } else {
          let files=[];
          lessons.forEach((lesson, index) => {  
            if (!lesson.title || !lesson.description) {
              lessonsValidationMessages.push(
                `Lesson ${
                  index + 1
                }: Title, Description, and Video are mandatory.`
              );
            } else {
              formData.append(`lessons[${index}][title]`, lesson.title);
              formData.append(
                `lessons[${index}][description]`,
                lesson.description
              );
              formData.append(`lessons[${index}][videoUrl]`, lesson.video);
              if (lesson.files) {
                lesson.files.forEach((file, fileIndex) => {
                  formData.append(
                    `lessons[${index}][files][${fileIndex}]`,
                    file
                  );
                });
              } else if (lesson.assetsArray) {
                files.push(lesson.assetsArray)
                lesson.assetsArray.forEach((file, fileIndex) =>{
                  if(file.id){ 
                    formData.append(
                      `lessons[${index}][assetsArray][${fileIndex}]`,
                      file  
                    );
                  }
                });
              }
            }
          });
            if(files.length > 0){
              formData.append('files', JSON.stringify(files))
            }
        }
        const evaluationsValidationMessages = [];
        if (evaluations.length === 0) {
          evaluationsValidationMessages.push(
            "Please add Evaluation questions."
          );
        } else {
          evaluations.forEach((evaluation, index) => {
            formData.append(`evaluations[${index}][type]`, evaluation.type);
            formData.append(
              `evaluations[${index}][question]`,
              evaluation.question
            );
            if (evaluation.type === "q&a") {
              formData.append(
                `evaluations[${index}][answer]`,
                evaluation.answer
              );
            } else if (
              evaluation.type === "options" &&
              Array.isArray(evaluation.options)
            ) {
              evaluation.options.forEach((option, optionIndex) => {
                formData.append(
                  `evaluations[${index}][options][${optionIndex}]`,
                  option
                );
              });
              formData.append(
                `evaluations[${index}][correctOption]`,
                evaluation.correctOption
              );
            } else if (
              evaluation.type === "multiple-options" &&
              Array.isArray(evaluation.options)
            ) {
              evaluation.options.forEach((option, optionIndex) => {
                formData.append(
                  `evaluations[${index}][options][${optionIndex}]`,
                  option
                );
              });
              formData.append(
                `evaluations[${index}][correctOption]`,
                evaluation.correctOption
              );
            }
          });
        }
        const allValidationMessages = [
          ...validationMessages,
          ...lessonsValidationMessages,
          ...evaluationsValidationMessages,
        ];

        if (allValidationMessages.length > 0) {
          let formattedMessages =
            '<div style="text-align: justify;height: 400px; overflow-y: auto;">';

          const validationGroups = {
            basicInfo: validationMessages,
            lessons: lessonsValidationMessages,
            Evaluation: evaluationsValidationMessages,
          };

          Object.keys(validationGroups).forEach((groupName) => {
            const messages = validationGroups[groupName];

            if (messages.length > 0) {
              formattedMessages += `<b style="margin-bottom: 10px;">${groupName.toUpperCase()}:</b><br>
        <ol style="list-style-type: decimal;">
          ${messages?.map((message) => `<li>${message}</li>`).join("")}
        </ol><br>`;
            }
          });

          formattedMessages += "</div>";
          setLoading(false);
          Swal.fire({
            title: "Please complete the following fields:",
            html: formattedMessages,
            padding: "3px",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
            showCloseButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
              content: "swal-wide-content",
            },
          });

          return;
        }
        if (courseId) {
          apiService.updateCourseById(courseId, formData).then((response) => {
            if (response?.status === 200) {
              setLoading(false);
              Swal.fire({
                title: "Course Updated",
                text: "The course has been updated successfully.",
                icon: "success",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
                customClass: {
                  title: "text-center",
                  content: "text-center",
                  confirmButton: "text-center",
                },
                padding: "3rem",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate(`/AdminManageCourse`);
                }
              });
            } else {
              setLoading(false);
              Swal.fire({
                title: "Error",
                text: "An error occurred while saving the course. Please try again later.",
                icon: "error",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
                customClass: {
                  title: "text-center",
                  content: "text-center",
                  confirmButton: "text-center",
                },
              });
            }
          });
        } else {
          apiService.saveAdminCourse(formData).then((response) => {
            setLoading(false);
            if (response?.data.message === "success") {              
              Swal.fire({
                title: "Course Saved",
                html: "The course has been saved successfully.<br> After adding this course, adjust the prerequisite requirements for all affected courses.",
                icon: "success",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
                padding: "3rem",
              }).then((result) => {
                if (result.isConfirmed) {
                  resetForm();
                  navigate(`/AdminManageCourse`);
                }
              });
            } else if (response?.data.message === "exist") {              
              Swal.fire({
                text: "No duplicate course titles allowed, go back",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
                customClass: {
                  title: "text-center",
                  content: "text-center",
                  confirmButton: "text-center",
                },
              })
            }
            else {
              Swal.fire({
                title: "Error",
                text: "An error occurred while saving the course. Please try again later.",
                icon: "error",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
                customClass: {
                  title: "text-center",
                  content: "text-center",
                  confirmButton: "text-center",
                },
              });
            }
          })
        }
      }, 0);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "An error occurred while saving the course. Please try again later.",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        customClass: {
          title: "text-center",
          content: "text-center",
          confirmButton: "text-center",
        },
      });
    } finally {
      setLoading(false);
    }
  };


  const handleSaveButtonTabClick = () => {
    
  }

  const handleVideoUrlChange = (e) =>{
    SetVideoUrl(e.target.value)
  }


  const editModalClose = () => {
    setShowEditModalEvaluation(false);
    setQuestionType("");
    setQuestionText("");
    setAnswerText("");
    setOptions([""]);
    setCorrectOption([])
  }

  return (
    <div className="AdMinAddCourse">
      <AdminHeader className="mb-4" />
      <Container className="mt-5">
        <Row>
          <Col>
            <Row>
              <Col sm={10}>
                <h3>
                  {" "}
                  COURSES / {courseId ? "UPDATE COURSE" : "CREATE COURSE"}
                </h3>
              </Col>
              <Col sm={2}>
                <Button
                  className="add-course-button"
                  onClick={handleManageCourse}
                >
                  Back To Courses
                </Button>
              </Col>
            </Row>

            <br />
            <Card className="tab-card">
              <Card.Body>
                <Tabs
                  defaultActiveKey="basic-info"
                  activeKey={activeTab}
                  onSelect={(k) => setActiveTab(k)}
                >
                  <Tab
                    eventKey="basic-info"
                    title={<span className="tab-title">Basic Info</span>}
                  >
                    <br />
                    <Form>
                      <Row>
                        <Col md={6}>
                          <Form.Group controlId="courseTitle">
                            <Form.Label>Title:</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter course title"
                              value={courseTitle || course?.courseTitle || ""}
                              onChange={(e) => setCourseTitle(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group controlId="courseDescription">
                            <Form.Label>Description:</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter course description"
                              value={
                                courseDescription ||
                                course?.courseDescription ||
                                ""
                              }
                              onChange={(e) =>
                                setcourseDescription(e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group controlId="courseCertificate">
                            <Form.Label>Under Certificates:</Form.Label>
                            <Form.Group>
                              <Form.Check
                                type="checkbox"
                                id="certificate-flow"
                                label="FLOW LEADERSHIP"
                                checked={certificates.includes(
                                  "FLOW LEADERSHIP"
                                )}
                                onChange={(e) =>
                                  handleCertificateChange(
                                    "FLOW LEADERSHIP",
                                    e.target.checked
                                  )
                                }
                              />
                              <Form.Check
                                type="checkbox"
                                id="certificate-inventory"
                                label="INVENTORY LEADERSHIP"
                                checked={certificates.includes(
                                  "INVENTORY LEADERSHIP"
                                )}
                                onChange={(e) =>
                                  handleCertificateChange(
                                    "INVENTORY LEADERSHIP",
                                    e.target.checked
                                  )
                                }
                              />
                              <Form.Check
                                type="checkbox"
                                id="certificate-scientist"
                                label="OPERATIONS SCIENCE LEADERSHIP"
                                checked={certificates.includes(
                                  "OPERATIONS SCIENCE LEADERSHIP"
                                )}
                                onChange={(e) =>
                                  handleCertificateChange(
                                    "OPERATIONS SCIENCE LEADERSHIP",
                                    e.target.checked
                                  )
                                }
                              />
                            </Form.Group>
                          </Form.Group>
                          <Form.Group controlId="prerequisites">
                            <Form.Label>Prerequisites:</Form.Label>

                            <div>
                              {getcoursetitle
                                ?.filter((course) =>
                                  courseId ? course.id !== courseId : true
                                )
                                ?.map((course) => (
                                  <Form.Check
                                    key={course.id}
                                    type="checkbox"
                                    id={`prerequisite-${course.id}`}
                                    label={course.courseTitle}
                                    value={course.courseTitle}
                                    checked={selectedPrerequisites.includes(
                                      course.courseTitle
                                    )}
                                    onChange={handlePrerequisitesChange}
                                  />
                                ))}
                              <Form.Check
                                key="none"
                                type="checkbox"
                                id="prerequisite-none"
                                label="None"
                                checked={selectedPrerequisites.length === 0 ? true : false} 
                                onChange={handlePrerequisitesChange}
                              />
                            </div>
                          </Form.Group>

                          <Form.Group controlId="coursePrice">
                            <Form.Label>Price:</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter course price"
                              value={
                                coursePrice !== null &&
                                coursePrice !== undefined
                                  ? coursePrice
                                  : course?.coursePrice !== undefined
                                  ? course?.coursePrice
                                  : ""
                              }
                              onChange={(e) => {
                                const inputValue =
                                  e.target.value === ""
                                    ? ""
                                    : Number(e.target.value);
                                if (
                                  !isNaN(inputValue) &&
                                  (inputValue >= 0 || inputValue === "")
                                ) {
                                  setCoursePrice(inputValue);
                                }
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          md={6}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <Form.Group>
                            <Form.Label className="thumbnail-label">
                              UPLOAD THUMBNAIL:
                            </Form.Label>
                            <div className="thumbnail-upload-container">
                              <div className="thumbnail-preview">
                                {imageUrl ? (
                                  <img
                                    src={imageUrl}
                                    alt="Uploaded Thumbnail"
                                    className="uploaded-image"
                                  />
                                ) : uploadedImage ? (
                                  <img
                                    src={URL.createObjectURL(uploadedImage)}
                                    alt="Uploaded Thumbnail"
                                    className="uploaded-image"
                                  />
                                ) : (
                                  <img
                                    src={addImageIcon}
                                    alt="Default Thumbnail"
                                    className="default-thumbnail"
                                  />
                                )}
                              </div>
                              <div className="upload-button-container">
                                <Button
                                  variant="secondary"
                                  size="sm"
                                  onClick={() =>
                                    imageFileInputRef.current.click()
                                  }
                                >
                                  {uploadedImage ? "Change" : "Upload Image"}
                                </Button>
                                <Form.Control
                                  type="file"
                                  ref={imageFileInputRef}
                                  style={{ display: "none" }}
                                  accept=".jpg, .png"
                                  onChange={(e) =>
                                    handleImageUpload(e.target.files)
                                  }
                                />
                              </div>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                    <Button
                      variant="primary"
                      onClick={handleSaveButtonClick}
                      className="mr-2 mt-3 d-flex gap-2 align-items-center"
                      disabled={loading}
                    >
                      <FontAwesomeIcon icon={faSave} className="mr-2" />
                      <span>{courseId ? " Update" : "Save"}</span>
                    </Button>
                  </Tab>

                  <Tab
                    eventKey="lessons"
                    title={<span className="tab-title">Lessons</span>}
                  >
                    <br />
                    <Form>
                      <Row>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>LESSON TITLE:</Form.Label>
                            <Form.Control
                              type="text"
                              value={lessonTitle || course?.lessonTitle || ""}
                              onChange={(e) => setLessonTitle(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>LESSON DESCRIPTION:</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={
                                lessonDescription ||
                                course?.lessonDescription ||
                                ""
                              }
                              onChange={(e) =>
                                setLessonDescription(e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Video URL:</Form.Label>
                            <Form.Control
                              type="text"
                              rows={3}
                              value={videoUrl || course?.videoUrl || ""}
                              onChange={handleVideoUrlChange}
                            />
                          </Form.Group>
                          <div className="cn-outer w-100">
                            <div className="mt-4">
                              <div className="formbold-main-wrapper">
                                <div className="formbold-form-wrapper">
                                  <div>
                                    <div className="cn-padding-border">
                                      <div className="d-flex flex-column justify-content-center cn-border-dashed cn-border-dashed-2">
                                        <div className="d-flex justify-content-center">
                                          <img src={uploadIcon} alt="" />
                                        </div>
                                        <div
                                          className="formbold-mb-5 formbold-file-input d-flex justify-content-center"
                                          onDrop={handleDrop}
                                          onDragOver={(e) => e.preventDefault()}
                                        >
                                          <input
                                            type="file"
                                            name="file"
                                            id="file"
                                            hidden
                                            onChange={handleFileSelect}
                                          />
                                          <label for="file">
                                            <div>
                                              <span
                                                className="formbold-drop-file cn-drag-text"
                                                style={{ cursor: "pointer" }}
                                              >
                                                Drag & drop files or
                                              </span>
                                              <span
                                                className="formbold-browse cn-browse-text"
                                                style={{ cursor: "pointer" }}
                                              >
                                                {" "}
                                                Browse{" "}
                                              </span>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {uploadedFiles.length !== 0 ? (
                            <div
                              className="mt-3 cn-outer w-100 "
                              id="uploaded-files"
                            >
                              <h4 className="cn-uploades-files d-flex justify-content-center ">
                                Uploaded Files: {uploadedFiles.length}/
                                {uploadedFiles.length}{" "}
                              </h4>
                              <div className="d-flex flex-column align-items-center flex-wrap gap-3 justify-content-center ">
                                {uploadedFiles.map((file, index) => (
                                  <>
                                    <div
                                      className="d-flex flex-column justify-content-end form-three-w-45 file-size-tooltip cursor-pointer"
                                      title={`${file.size} bytes`}
                                      key={index}
                                    >
                                      <div className="file-added d-flex justify-content-between w-100 gap-3">
                                        <div className="file-name-text">
                                          {file.name}{" "}
                                        </div>
                                        <div className="">
                                          <Button
                                            variant="link"
                                            className="icon-button"
                                            onClick={() =>
                                              handleRemoveFile(index)
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faTrashAlt}
                                            />
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Col>
                        <Col
                          md={6}
                          className="d-flex justify-content-center align-items-center"
                        >
                          {videoUrl ? (
                            <iframe
                              src={`${
                                process.env.REACT_APP_VIMEO_VIDEO
                              }${getVimeoID(videoUrl)}`}
                              width="640"
                              height="564"
                              frameborder="0"
                              allow="autoplay; fullscreen"
                              allowfullscreen
                            ></iframe>
                          ) : (
                            <img
                              src={addVideoIcon}
                              alt="video icon"
                              width="200"
                              height="200"
                            />
                          )}
                        </Col>
                      </Row>
                    </Form>

                    <br />
                    <Row>
                      <Col
                        md={12}
                        className="d-flex justify-content-end align-items-end"
                      >
                        <div>
                          <Button onClick={handleAddLesson}>Add lesson</Button>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    {lessons.length > 0 && (
                      <Card className="lesson-card">
                        <Card.Header>LESSONS</Card.Header>
                        <Card.Body>
                          <div className="lesson-list">
                            <div className="lesson-heading">
                              <div>Video</div>
                              <div>Title</div>
                              <div>Description</div>
                              <div>Files</div>
                              <div>Action</div>
                            </div>

                            {lessons?.map((lesson, indexMain) => (
                              <>
                                <div key={indexMain} className="lesson-item">
                                  <div className="lesson-video">
                                    {lesson.video ? (
                                      <>
                                        <iframe
                                          src={`${process.env.REACT_APP_VIMEO_VIDEO}${lesson.video}`}
                                          width="200"
                                          height="150"
                                          title="Vimeo video"
                                        ></iframe>
                                      </>
                                    ) : (
                                      <p>Not uploaded</p>
                                    )}
                                  </div>
                                  <div>{lesson.title}</div>
                                  <div>{lesson.description}</div>
                                  <div>
                                    {lesson.assetsArray || lesson.files
                                      ? lesson.assetsArray
                                        ? `${lesson.assetsArray.length} files added`
                                        : `${lesson.files.length} files added`
                                      : ""}
                                  </div>
                                  <div className="lesson-actions">
                                    <Button
                                      variant="link"
                                      className="icon-button"
                                      onClick={() => handleEditLesson(lesson, indexMain)}
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                    <Button
                                      variant="link"
                                      className="icon-button"
                                      onClick={() => handleDeleteLesson(lesson)}
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                  </div>
                                </div>
                                <Modal
                                  show={showEditModal}
                                  onHide={() => setShowEditModal(false)}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>Edit Lesson</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                                    <Form>
                                      <Form.Group controlId="editLessonTitle">
                                        <Form.Label>Title:</Form.Label>
                                        <Form.Control
                                          type="text"
                                          value={
                                            editableLesson
                                              ? editableLesson.title
                                              : ""
                                          }
                                          onChange={(e) =>
                                            setEditableLesson({
                                              ...editableLesson,
                                              title: e.target.value,
                                            })
                                          }
                                        />
                                      </Form.Group>
                                      <Form.Group controlId="editLessonDescription">
                                        <Form.Label>Description:</Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={3}
                                          value={
                                            editableLesson
                                              ? editableLesson.description
                                              : ""
                                          }
                                          onChange={(e) =>
                                            setEditableLesson({
                                              ...editableLesson,
                                              description: e.target.value,
                                            })
                                          }
                                        />
                                      </Form.Group>
                                      <Form.Group controlId="editLessonVideoUrl">
                                        <Form.Label>Edit Video URL:</Form.Label>
                                        <Form.Control
                                          type="text"
                                          rows={3}
                                          value={
                                            editableLesson
                                              ? editableLesson.video
                                              : ""
                                          }
                                          onChange={(e) =>
                                            setEditableLesson({
                                              ...editableLesson,
                                              video: e.target.value,
                                            })
                                          }
                                        />
                                      </Form.Group>
                                      <Form.Group>
                                        <div className="cn-outer w-100">
                                          <div className="mt-4">
                                            <div className="formbold-main-wrapper">
                                              <div className="formbold-form-wrapper">
                                                <div>
                                                  <div className="cn-padding-border">
                                                    <div className="d-flex flex-column justify-content-center cn-border-dashed cn-border-dashed-2">
                                                      <div className="d-flex justify-content-center">
                                                        <img
                                                          src={uploadIcon}
                                                          alt=""
                                                        />
                                                      </div>
                                                      <div
                                                        className="formbold-mb-5 formbold-file-input d-flex justify-content-center"
                                                        onDrop={
                                                          handleDropUpdate
                                                        }
                                                        onDragOver={(e) =>
                                                          e.preventDefault()
                                                        }
                                                      >
                                                        <input
                                                          type="file"
                                                          name="fileUpdate"
                                                          id="fileUpdate"
                                                          hidden
                                                          onChange={
                                                            handleFileSelectUpdate
                                                          }
                                                        />
                                                        <label for="fileUpdate">
                                                          <div>
                                                            <span
                                                              className="formbold-drop-file cn-drag-text"
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                            >
                                                              Drag & drop files
                                                              or
                                                            </span>
                                                            <span
                                                              className="formbold-browse cn-browse-text"
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                            >
                                                              {" "}
                                                              Browse{" "}
                                                            </span>
                                                          </div>
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {
                                          (editableLesson?.files?.length !== 0 || editableLesson?.assetsArray?.length !== 0) ? (
                                          <div
                                            className="mt-3 cn-outer w-100 "
                                            id="uploaded-files"
                                          >
                                            <h4 className="cn-uploades-files d-flex justify-content-center ">
                                              Uploaded Files:{" "}
                                              {(editableLesson?.files?.length || 0) + (editableLesson?.assetsArray?.length || 0)}/
                                              {(editableLesson?.files?.length || 0) + (editableLesson?.assetsArray?.length || 0)}
                                            </h4>
                                            <div className="d-flex flex-column align-items-center flex-wrap gap-3 justify-content-center ">
                                            {(editableLesson?.files || []).map((file, index) => (
                                                <div
                                                  key={`file-${index}`}
                                                  className="d-flex flex-column justify-content-end form-three-w-45 file-size-tooltip cursor-pointer"
                                                  title={`${file.size} bytes`}
                                                >
                                                  <div className="file-added d-flex justify-content-between w-100 gap-3">
                                                    <div className="file-name-text">
                                                      {file.name}
                                                    </div>
                                                    <div>
                                                      <Button
                                                        variant="link"
                                                        className="icon-button"
                                                        onClick={() => handleRemoveFileUpdate(index)}
                                                      >
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                              {(editableLesson?.assetsArray || []).map((file, index) => (
                                                <div
                                                  key={`asset-${index}`}
                                                  className="d-flex flex-column justify-content-end form-three-w-45 file-size-tooltip cursor-pointer"
                                                  title={`${file.size} bytes`}
                                                >
                                                  <div className="file-added d-flex justify-content-between w-100 gap-3">
                                                    <div className="file-name-text">
                                                    {
                                                      file.filename ? 
                                                        file.filename : file.name ? file.name : `file ${index + 1}`
                                                    }
                                                    </div>
                                                    <div>
                                                      <Button
                                                        variant="link"
                                                        className="icon-button"
                                                        onClick={() => handleRemoveFileUpdate(index)}
                                                      >
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </Form.Group>
                                    </Form>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={() => setShowEditModal(false)}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="primary"
                                      onClick={() => handleUpdateLesson(currentLessonIndex)}
                                    >
                                      Save Changes
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </>
                            ))}
                          </div>
                        </Card.Body>
                      </Card>
                    )}

                    <Button
                      variant="primary"
                      onClick={handleSaveButtonClick}
                      className="mr-2 mt-3"
                      disabled={loading}
                    >
                      <FontAwesomeIcon icon={faSave} className="mr-2" />
                      {courseId ? " Update" : " Save"}
                    </Button>
                  </Tab>

                  <Tab
                    eventKey="evaluation"
                    title={
                      <span className="tab-title">Evaluation Questions</span>
                    }
                  >
                    <Form>
                      <Form.Group controlId="questionType">
                        <Form.Label>Question Type:</Form.Label>
                        <Form.Control
                          as="select"
                          value={questionType}
                          onChange={(e) => setQuestionType(e.target.value)}
                        >
                          <option value="q&a">Question and answer</option>
                          <option value="options">Single option</option>
                          <option value="multiple-options">
                            Multiple options
                          </option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="questionText">
                        <Form.Label>Question:</Form.Label>
                        <Form.Control
                          type="text"
                          value={questionText}
                          onChange={(e) => setQuestionText(e.target.value)}
                        />
                      </Form.Group>
                      {questionType === "q&a" && (
                        <Form.Group controlId="answerText">
                          <Form.Label>Answer:</Form.Label>
                          <Form.Control
                            type="text"
                            value={answerText}
                            onChange={(e) => setAnswerText(e.target.value)}
                          />
                        </Form.Group>
                      )}
                      {questionType === "options" && Array.isArray(options) && (
                        <>
                          {options?.map((option, index) => (
                            <Form.Group
                              controlId={`option${index}`}
                              key={index}
                            >
                              <Form.Label>Option {index + 1}:</Form.Label>
                              <Form.Control
                                type="text"
                                value={option}
                                onChange={(e) =>
                                  handleOptionChange(index, e.target.value)
                                }
                              />
                            </Form.Group>
                          ))}
                          {options.length < 6 && (
                            <Button
                              variant="secondary"
                              onClick={handleAddOption}
                            >
                              Add Option
                            </Button>
                          )}
                          {options.length > 1 && (
                            <Button
                              variant="danger"
                              onClick={handleDeleteOption}
                            >
                              Delete Option
                            </Button>
                          )}
                          <Form.Group controlId="correctOptions">
                            <Form.Label>Select Correct Options:</Form.Label>
                            {options?.map((option, index) => (
                              <Form.Check
                                key={index}
                                type="checkbox"
                                id={`option-${index}`}
                                label={option}
                                checked={correctOption.includes(option)}
                                onChange={() =>
                                  handleOptionAnswerChange(option)
                                }
                              />
                            ))}
                          </Form.Group>
                        </>
                      )}
                      {questionType === "multiple-options" && Array.isArray(options) && (
                        <>
                          {options?.map((option, index) => (
                            <Form.Group
                              controlId={`option${index}`}
                              key={index}
                            >
                              <Form.Label>Option {index + 1}:</Form.Label>
                              <Form.Control
                                type="text"
                                value={option}
                                onChange={(e) =>
                                  handleOptionChange(index, e.target.value)
                                }
                              />
                            </Form.Group>
                          ))}
                          {options.length < 6 && (
                            <Button
                              variant="secondary"
                              onClick={handleAddOption}
                            >
                              Add Option
                            </Button>
                          )}
                          {options.length > 1 && (
                            <Button
                              variant="danger"
                              onClick={handleDeleteOption}
                            >
                              Delete Option
                            </Button>
                          )}
                          <Form.Group controlId="correctOptions">
                            <Form.Label>Select Correct Options:</Form.Label>
                            {options?.map((option, index) => (
                              <Form.Check
                                key={index}
                                type="checkbox"
                                id={`option-${index}`}
                                label={option}
                                checked={correctOption.includes(option)}
                                onChange={() =>
                                  handleMultipleOptions(option)
                                }
                              />
                            ))}
                          </Form.Group>
                        </>
                      )}
                    </Form>
                    <br />
                    <Row>
                      <Col
                        md={12}
                        className="d-flex justify-content-end align-items-end"
                      >
                        <Button variant="primary" onClick={handleAddEvaluation}>
                          Add Evaluation
                        </Button>
                      </Col>
                    </Row>
                    <br />
                    {evaluations.length > 0 && (
                      <Card className="evaluation-card">
                        <Card.Header>Evaluations</Card.Header>
                        <Card.Body>
                          <div className="evaluation-list">
                            <div className="evaluation-heading">
                              <div>Question</div>
                              <div>Type</div>
                              <div>Details</div>
                              <div>Action</div>
                            </div>

                            {evaluations?.map((evaluation, index) => (
                              <>
                              <div key={index} className="evaluation-item">
                                <div>{`Question ${index + 1}`}</div>
                                <div>{evaluation.type}</div>                               
                                <div>
                                  <p>Question: {evaluation.question}</p>
                                  {evaluation.type === "options" &&
                                    Array.isArray(evaluation.options) && (
                                      <p>
                                        Options:
                                        {evaluation?.options?.map(
                                          (option, idx) => (
                                            <span
                                              key={idx}
                                              style={{
                                                fontWeight:
                                                  evaluation?.correctOption?.includes(
                                                    option
                                                  )
                                                    ? "bold"
                                                    : "normal",
                                                color:
                                                  evaluation?.correctOption?.includes(
                                                    option
                                                  )
                                                    ? "green"
                                                    : "inherit",
                                              }}
                                            >
                                              {`${String.fromCharCode(
                                                97 + idx
                                              )}. ${option}`}
                                              {idx !==
                                                evaluation.answer?.length - 1 &&
                                                ", "}
                                            </span>
                                          )
                                        )}
                                      </p>
                                  )}

                                  {evaluation.type === "q&a" && (
                                    <p>Answer q&a: {evaluation.answer}</p>
                                  )}
                                  {evaluation.type === "multiple-options" &&
                                    evaluation?.options?.length > 0 && (
                                      <p>
                                        Options:
                                        {evaluation.options?.map(
                                          (option, idx) => (
                                            <span
                                              key={idx}
                                              style={{
                                                fontWeight:
                                                  evaluation.correctOption.includes(
                                                    option
                                                  )
                                                    ? "bold"
                                                    : "normal",
                                                color:
                                                  evaluation.correctOption.includes(
                                                    option
                                                  )
                                                    ? "green"
                                                    : "inherit",
                                              }}
                                            >
                                              {`${String.fromCharCode(
                                                97 + idx
                                              )}. ${option}`}
                                              {idx !==
                                                evaluation.options.length - 1 &&
                                                ", "}
                                            </span>
                                          )
                                        )}
                                      </p>
                                    )}
                                </div>
                                <div className="lesson-actions">
                                  <Button
                                    variant="link"
                                    className="icon-button"
                                    onClick={() =>
                                      handleEditEvaluation(evaluation)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                  <Button
                                    variant="link"
                                    className="icon-button"
                                    onClick={() =>
                                      handleDeleteEvaluation(evaluation)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </Button>
                                </div>
                              </div>
                    <Modal
                      show={showEditModalEvaluation}
                      onHide={editModalClose}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {editedEvaluation
                            ? "Edit Evaluation"
                            : "Add Evaluation"}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                        <Form>
                          <Form.Group controlId="questionType">
                            <Form.Label>Question Type : {questionType}</Form.Label>
                          </Form.Group>
                          <Form.Group controlId="questionText">
                            <Form.Label>Question:</Form.Label>
                            <Form.Control
                              type="text"
                              value={questionText}
                              onChange={(e) => setQuestionText(e.target.value)}
                            />
                          </Form.Group>
                          {questionType === "q&a" && (
                            <Form.Group controlId="answerText">
                              <Form.Label>Answer:</Form.Label>
                              <Form.Control
                                type="text"
                                value={answerText}
                                onChange={(e) => setAnswerText(e.target.value)}
                              />
                            </Form.Group>
                          )}
                          {questionType === "options" && (
                            <>
                              {options && options?.map((option, index) => (
                                <Form.Group
                                  controlId={`option${index}`}
                                  key={index}
                                >
                                  <Form.Label>Option {index + 1}:</Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={option}
                                    onChange={(e) =>
                                      handleOptionChange(index, e.target.value)
                                    }
                                  />
                                </Form.Group>
                              ))}
                              {options && options?.length < 6 && (
                                <Button
                                  variant="secondary"
                                  onClick={handleAddOption}
                                >
                                  Add Option
                                </Button>
                              )}
                              <Form.Group controlId="correctOptions">
                                <Form.Label>Select Correct Options:</Form.Label>
                                <Form.Control
                                  as="select"
                                  multiple
                                  value={correctOption}
                                  onChange={(e) => {
                                    const selectedOptions = Array.from(
                                      e.target.selectedOptions,
                                      (option) => option.value
                                    );
                                                setCorrectOption(
                                                  selectedOptions
                                                );
                                              }}
                                            >
                                              {options?.map((option, index) => (
                                    <option value={option} key={index}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            </>
                          )}
                          {questionType === "multiple-options" && (
                            <>
                              {options && options?.map((option, index) => (
                                <Form.Group
                                  controlId={`option${index}`}
                                  key={index}
                                >
                                  <Form.Label>Option {index + 1}:</Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={option}
                                    onChange={(e) =>
                                      handleOptionChange(index, e.target.value)
                                    }
                                  />
                                </Form.Group>
                              ))}
                              {options?.length < 6 && (
                                <Button
                                  variant="secondary"
                                  onClick={handleAddOption}
                                >
                                  Add Option
                                </Button>
                              )}
                              <Form.Group controlId="correctOptions">
                                <Form.Label>Select Correct Options:</Form.Label>
                                <Form.Control
                                  as="select"
                                  multiple
                                  value={correctOption}
                                  onChange={(e) => {
                                    const selectedOptions = Array.from(
                                      e.target.selectedOptions,
                                      (option) => option.value
                                    );
                                                setCorrectOption(
                                                  selectedOptions
                                                );
                                              }}
                                            >
                                              {options?.map((option, index) => (
                                    <option value={option} key={index}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            </>
                          )}
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          // onClick={() => setShowEditModalEvaluation(false)}
                          onClick={editModalClose}
                        >
                          Close
                        </Button>
                        {editedEvaluation ? (
                          <Button
                            variant="primary"
                            onClick={handleUpdateEvaluation}
                          >
                            Save Changes
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            onClick={handleAddEvaluation}
                          >
                            Add Evaluation
                          </Button>
                        )}
                      </Modal.Footer>
                    </Modal>
                              </>
                            ))}
                          </div>
                        </Card.Body>
                      </Card>
                    )}
                    <br />

                    <Button
                      variant="primary"
                      onClick={handleSaveButtonClick}
                      className="mr-2 mt-3"
                      disabled={loading}
                    >
                      <FontAwesomeIcon icon={faSave} className="mr-2" />
                      {courseId ? " Update" : " Save"}
                    </Button>
                  </Tab>

                  <Tab
                    eventKey="course-status"
                    title={<span className="tab-title">Course Status</span>}
                  >
                    <br />
                    <Row>
                      <Col>
                        <Form>
                          <Form.Group controlId="courseStatus">
                            <Form.Label>Course Status:</Form.Label>
                            <div key="inline-radio" className="mb-3">
                              <Form.Check
                                inline
                                label="Publish"
                                type="radio"
                                id="publish"
                                checked={courseStatus === "publish"}
                                onChange={() => handleStatusChange("publish")}
                              />                              
                              <Form.Check
                                inline
                                label="Draft"
                                type="radio"
                                id="draft"
                                checked={courseStatus === "draft"}
                                onChange={() => handleStatusChange("draft")}
                              />
                              <Form.Check
                                inline
                                label="viewOnly"
                                type="radio"
                                id="viewOnly"
                                checked={courseStatus === "viewOnly"}
                                onChange={() => handleStatusChange("viewOnly")}
                              />
                            </div>
                          </Form.Group>
                        </Form>
                      </Col>
                    </Row>
                    <Row className="mt-3 d-flex justify-content-end align-items-end">
                      <Col
                        sm={2}
                        className="d-flex justify-content-end align-items-end"
                      >
                        <Button
                          variant="primary"
                          onClick={handleSaveButtonClick}
                          className="mr-2"
                          disabled={loading}
                        >
                          <FontAwesomeIcon icon={faSave} className="mr-2" />
                          {courseId ? " Update" : " Save"}
                        </Button>
                      </Col>
                      <Col
                        sm={1}
                        className="d-flex justify-content-end align-items-end"
                      >
                        <Button variant="secondary" onClick={handleCancel}>
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="mt-3 d-flex justify-content-end align-items-end gap-5 ml-4">
          <div width="auto">
            <Button
              onClick={handlePrevious}
              disabled={activeTab === "basic-info"}
              block
              className="Previous_Button"
            >
              Previous
            </Button>
          </div>
          <div className="text-right" width="auto">
            <Button
              onClick={handleNext}
              disabled={activeTab === "course-status"}
              block
              className="Next_Button"
            >
              Next
            </Button>
          </div>
        </div>
      </Container>

      <br />
      <br />
      <br />

      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
};

export default AdminAddCourse;