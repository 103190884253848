// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-left {
    text-align: left !important;;
  }


  .margin-top-text{
    margin-top: 30px !important;
  }`, "",{"version":3,"sources":["webpack://./src/css/EditProfile.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;EAC7B;;;EAGA;IACE,2BAA2B;EAC7B","sourcesContent":[".text-left {\n    text-align: left !important;;\n  }\n\n\n  .margin-top-text{\n    margin-top: 30px !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
