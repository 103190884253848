import React, { useEffect, useState } from "react";
import AdminHeader from "./Admin/AdminHeader";
import UserHeader from "./User/UserHeader";
import Logo from "../assets/LOGO.png";
import homeBanner from "../assets/homeBanner.png";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "../css/Home.css";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "../Common/firebase";
import apiService from "../services/api.service";
const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col sm={2} className="text-center">
            <img
              src={Logo}
              alt="Logo"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Col>
          <Col sm={1} className="text-center">
            <div className="separator"></div>
          </Col>
          <Col sm={3} className="text-center align-self-center">
            <div className="d-flex flex-column ">
              <p>ONLINE TRAINING</p>
              <div className="d-flex gap-4">
                <a
                  href="/terms-and-conditions"
                  className="terms-privacy-link-home"
                >
                  Terms of Service
                </a>
                <a href="/privacy" className="terms-privacy-link-home">
                  Privacy Policy
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="d-flex justify-content-center mt-4">
            <span className="">
              © 2024 Operations Science Institute. All rights reserved.
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
};
const Home = () => {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(localStorage?.getItem("userRole"));
  const [selectedRole, setSelectedRole] = useState(null);
  const [userId, setUserId] = useState(localStorage?.getItem("userId"));
  const [cartCount, setCartCount] = useState(0);
  const handleFreeCourse = () => {
    navigate(`/SignUp`);
  };
  const handleAdminAddCourse = () => {
    navigate(`/Admin`);
  };
  const handleuserViewCourse = () => {
    navigate(`/UserMyCourse`);
  };
  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const userDoc = await firestore
            .collection("users")
            .doc(currentUser.uid)
            .get();
          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserRole(userData.role);
            localStorage.setItem("userRole", userData.role);
          }
        }
      } catch (error) {
        console.error("Error fetching user role:", error.message);
      }
    };

    fetchUserRole();
    const animateCols = document.querySelectorAll(".animate-col");
    const animateImages = document.querySelectorAll(".animate-image");

    const handleScroll = () => {
      animateCols.forEach((col) => {
        const colTop = col.getBoundingClientRect().top;
        const screenHeight = window.innerHeight;

        if (colTop < screenHeight * 0.75) {
          col.classList.add("animate");
        }
      });

      animateImages.forEach((image) => {
        const imageTop = image.getBoundingClientRect().top;
        const screenHeight = window.innerHeight;

        if (imageTop < screenHeight * 0.75) {
          image.classList.add("animate");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {

    const fetchCartCount = async () => {
      try {
        if (!userId) {
          return;
        }
        const response = await apiService.getCart(userId);
        if (response && response?.data?.items) {
          setCartCount(response?.data?.items.length);
        }
      } catch (error) {
        console.error("Error fetching cart count:", error);
      }
    };

    fetchCartCount();
  }, [userId]);
  return (
    <div className="ManageAllCourse">
      {userRole === "user" || userRole === "User" ? (
        <UserHeader className="mb-4" cartCount={cartCount} />
      ) : (
        <AdminHeader className="mb-4" />
      )}
      <br />

      <div className="courses-list">
        <Container className="mt-5">
          <Row id="animateRow1">
            <Col className="animate-row left-to-right col-md-custom text-width-res">
              <h2>Operations Science Online Training</h2>
              <br />
              <p className="mb-0">
                Your people and your technology can rapidly solve your business
                challenges with operations science. No need for expensive
                consultants or new software.
              </p>
              <br />
              {(userRole === "Admin" ||
                userRole === "admin" ||
                userRole === "Master") && (
                <>
                  <div className="d-flex justify-content-center ">
                    <Button onClick={handleAdminAddCourse}>Add Course</Button>
                  </div>
                </>

              )}

              {userRole?.toLowerCase() === "user" && (
                <>
                  <div className="d-flex justify-content-center ">
                    <Button onClick={handleuserViewCourse}>MY COURSES</Button>
                  </div>
                </>
              )}

              {!userRole && (
                <>
                  <div className="d-flex justify-content-center ">
                    <Button onClick={handleFreeCourse}>Create Account</Button>
                  </div>
                </>
              )}

              <br />
              <p className="mb-0">
                Powerful, practical professional development from the Operations Science Institute <br />
                - Learn from decades of operations science development   <br />
                - Earn a certificate <br />
                - Transform your career, accelerate success
              </p>
              <br />
              <p>
                Whether you are an individual contributor leading from within or
                an executive responsible for an entire organization, operations
                science will strengthen your leadership. Operations science
                provides an objective framework to provide predictability, and
                reduce stress and waste in operations management.
              </p>
            </Col>
            <Col md={4} className="animate-col">
              <img
                src={homeBanner}
                alt="Uploaded Thumbnail"
                className="uploaded-im  ge animate-image home-image"
              />
            </Col>
          </Row>
          <br />
          <Row id="animateRow2" className="animate-row right-to-left">
            <Col md={12} className="animate-col">
              <h2>
                Operations Science Training Inspires, Energizes, and Sustains
                Success
              </h2>
              <br />
              <p className="margin-bottom-zero">
                Operations science is essential for predictable, successful
                management of any operations or projects.
              </p>
              <br />
              <p className="margin-bottom-zero">
                “The popular continuous improvement programs are good.
                Operations science is all of those but stripped of the
                consulting stuff” – Mike McBride, Vice President, Maintenance
                Operations, Delta Airlines
              </p>
              <br />
              <p className="margin-bottom-zero">
                “I would not let any manager lead operations without them having
                first demonstrated knowledge of operations science.”
              </p>
              <br />
            </Col>
          </Row>
          <br />
          <Row id="animateRow3" className="animate-row left-to-right">
            <Col md={12} className="animate-col">
              <h2>Operations Science for Organizational Development</h2>
              <br />
              <p className="margin-bottom-zero">
                For executives seeking to align and motivate their
                organization’s efforts, the quickest and most effective route is
                to create customized operations science training—either online
                or in-person—tailored specifically for the organization’s work
                environment and goals. The resulting professional development
                will strengthen your work force and accelerate success.
              </p>
              <br />
              <p className="margin-bottom-zero">
                Sustain long-term success by certifying your own trainers to
                train your organization on operations science concepts and
                application.
              </p>
              <br />
              <p>
                Send an email to info@opscience.org to get started. Go to the
                Operations Science Institute’s website to access more great
                resources and information about operations science.
              </p>
              <br />
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col md={2} className="text-center">
              <Button
                onClick={() =>
                  (window.location.href = process.env.REACT_APP_CONTACT_US)
                }
              >
                CONTACT US
              </Button>
            </Col>
            <Col md={4} className="text-center">
              <Button
                className="opi-text-res"
                onClick={() =>
                  (window.location.href = process.env.REACT_APP_OSI_URL)
                }
              >
                OPERATIONS SCIENCE INSTITUTE
              </Button>
            </Col>
          </Row>
        </Container>
        <br />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
