import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { auth } from '../Common/firebase';
import '../css/signUp.css';
import Swal from 'sweetalert2';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from "react-router-dom";
import apiService from '../services/api.service';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState([]);
    const [message, setMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const [mode, setMode] = useState("")
  
    const query = new URLSearchParams(useLocation().search);
    const oobCode = query.get('oobCode'); 


    useEffect(() => {
      setMode(query.get('mode'))
    }, [])
    

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
  
    const validatePassword = (password) => {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
      return regex.test(password);
    };
  
    const handleResetPassword = async (e) => {
      e.preventDefault();
      if (!validatePassword(password)) {
        setError([
          "Password requirements",
          "- at least 8 characters in length",
          "- at least one uppercase and one lowercase letter",
          "- use at least one special character !@#$%^&*()",
        ]);
        return;
      }
      if (password !== confirmPassword) {
        setError('Passwords do not match.');
        return;
      }
      try {
        await auth.confirmPasswordReset(oobCode, password);
        setMessage('Password has been reset successfully.');
        navigate('/home')
        setError('');
      } catch (error) {
        setError(error.message);
      }
    };
  
    const handleVerifyEmail = async (e) => {
      // e.preventDefault();
      if (!oobCode) {
        setError(
          "Missing verification code. Please request a new verification email."
        );
        return;
      }
      try {
        await auth
          .applyActionCode(oobCode)
          .then(async () => {
            const user = auth.currentUser; 
            if (user) {
              navigate("/login");
              await apiService.welcomeMail(user.email);
            } else {
              console.log("No user is currently signed in.");
            }
          })
          .catch((error) => {
            console.log("error", error);
        });
        setError("");
      } catch (error) {
        setError(error.message);
      }
    };

    return (
      <>
        {mode === "verifyEmail" ? (
          <>
            <div className="Login">
              <div
                style={{
                  backgroundColor: "#F5FCF8",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "90vh",
                }}
              >
                <div className="card" style={{ width: "30rem" }}>
                  <div className="center-align-container">
                    <span className="Create_Account_Title">
                      Verify Your Email
                    </span>
                  </div>
                  <div className="card-body">
                    <div className="center-align-container">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleVerifyEmail}
                        // onTouchStart={handleVerifyEmail}
                      >
                        VERIFY EMAIL
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="Login">
            <div
              style={{
                backgroundColor: "#F5FCF8",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
              }}
            >
              <div className="card" style={{ width: "30rem" }}>
                <div className="center-align-container">
                  <span className="Create_Account_Title">
                    Reset your password
                  </span>
                </div>
                <div className="center-align-container">
                  <p className="Singup_Text">
                    Enter password below. <br /> Password are required
                    to have at least: <br />
                    - 8 characters <br />
                    - one uppercase and one lowercase letter <br />- one of the
                    following characters !@#$%^&*()
                  </p>
                </div>
                <div className="card-body">
                  {message && <p className="text-success">{message}</p>}
                  {error &&
                    error.map((error, index) => <div key={index}>{error}</div>)}
                  <form onSubmit={handleResetPassword}>
                    <div className="form-group password-input">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter new password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control"
                      />
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        className="password-toggle-icon"
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                    <div className="form-group password-input">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="center-align-container">
                      <button type="submit" className="btn btn-primary">
                        RESET PASSWORD
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
};

export default ResetPassword;
