import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "../../css/AdminHeader.css";
import { auth, firestore } from "../../Common/firebase";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Layout from "../Layouts/Layout";

const CustomNavbar = ({ cartCount }) => {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(localStorage?.getItem("userRole"));
  const [coursesDropdownOpen, setCoursesDropdownOpen] = useState(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const navbarStyle = {
    backgroundColor: "#70AD47",
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const userDoc = await firestore
            .collection("users")
            .doc(currentUser.uid)
            .get();
          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserRole(userData.role);
            localStorage.setItem("userRole", userData.role);
          }
        }
      } catch (error) {
        console.error("Error fetching user role:", error.message);
      }
    };

    fetchUserRole();
  }, []);

  const handleLogout = async () => {
    const confirmationResult = await Swal.fire({
      title: "Are you sure you want to logout?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
    if (confirmationResult.isConfirmed) {
      try {
        localStorage.removeItem("userRole");
        setUserRole(null);
        await auth.signOut();
        navigate(`/login`);
      } catch (error) {
        console.error("Error during logout:", error.message);
      }
    }
  };

  const handleCoursesDropdownToggle = () => {
    setCoursesDropdownOpen(!coursesDropdownOpen);
  };

  const handleProfileDropdownToggle = () => {
    setProfileDropdownOpen(!profileDropdownOpen);
  };

  const rightLinksStyle = {
    marginLeft: "auto",
    paddingRight: "5%",
  };

  return (
    <>
    <Navbar collapseOnSelect expand="lg" style={navbarStyle} variant="dark">
      <Nav className="mr-auto ml-10px">
        <Nav.Link href="/Home" className="Home">
          HOME
        </Nav.Link>
        <Nav.Link href={process.env.REACT_APP_WHY_OS} target="_blank" rel="noopener noreferrer">
          WHY OS?
        </Nav.Link>
        <NavDropdown
          title="COURSES"
          id="collasible-nav-dropdown-courses"
          show={coursesDropdownOpen}
          onMouseEnter={handleCoursesDropdownToggle}
          onMouseLeave={handleCoursesDropdownToggle}
        >
          <NavDropdown.Item href="/CourseLibrary">
            Course Library
          </NavDropdown.Item>
          <NavDropdown.Item href="/UserCourseSelection">
            Course Selection
          </NavDropdown.Item>
          <NavDropdown.Item href="/UserMyCourse">My Courses</NavDropdown.Item>
        </NavDropdown>
      </Nav>
      <Navbar.Brand className="osi-heading">
        OPERATIONS SCIENCE INSTITUTE
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto" style={rightLinksStyle}>
          <Nav.Link href={process.env.REACT_APP_HOW_TO_START} target="_blank" rel="noopener noreferrer" >
            HOW TO START
          </Nav.Link>
          <Nav.Link href="/cart" className="cart">
            CART ({cartCount})
          </Nav.Link>
          {userRole && (
            <NavDropdown
              title="PROFILE"
              id="collasible-nav-dropdown-profile"
              show={profileDropdownOpen}
              onMouseEnter={handleProfileDropdownToggle}
              onMouseLeave={handleProfileDropdownToggle}
            >
              <NavDropdown.Item href="/EditProfile">
                Edit Profile
              </NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={handleLogout}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
     <Layout/>
    </>
  );
};

export default CustomNavbar;
