// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CourseNotPurchased{
    background-color: #000000 !important;
    color: rgb(255, 255, 255) !important;
  }
  .CourseAddedNotPurchased{
    background-color: #50A3ED !important;
    color: rgb(255, 255, 255) !important;
  }
.CoursePurchased{
    background-color: #70AD47 !important;
    color: rgb(255, 255, 255) !important;
  }
.CourseAddedToCart{
    background-color: #50A3ED !important;
    color: rgb(255, 255, 255) !important;
  }
  .swal2-html-container
  {
      display: block;
      left: 12% !important;
      width: 66% !important;
      position: relative !important;
  }

  .custom-list {
    list-style-type: none; 
}

.custom-list li:before {
    content: "-"; 
    margin-right: 0.5em; 
}

.pl-zero{
  padding-left: 0px;
}

@media (max-width: 1186px) {
  .price-table th, .price-table td {
    font-size: 11px;
  }
}`, "",{"version":3,"sources":["webpack://./src/css/userSelection.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,oCAAoC;EACtC;EACA;IACE,oCAAoC;IACpC,oCAAoC;EACtC;AACF;IACI,oCAAoC;IACpC,oCAAoC;EACtC;AACF;IACI,oCAAoC;IACpC,oCAAoC;EACtC;EACA;;MAEI,cAAc;MACd,oBAAoB;MACpB,qBAAqB;MACrB,6BAA6B;EACjC;;EAEA;IACE,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".CourseNotPurchased{\n    background-color: #000000 !important;\n    color: rgb(255, 255, 255) !important;\n  }\n  .CourseAddedNotPurchased{\n    background-color: #50A3ED !important;\n    color: rgb(255, 255, 255) !important;\n  }\n.CoursePurchased{\n    background-color: #70AD47 !important;\n    color: rgb(255, 255, 255) !important;\n  }\n.CourseAddedToCart{\n    background-color: #50A3ED !important;\n    color: rgb(255, 255, 255) !important;\n  }\n  .swal2-html-container\n  {\n      display: block;\n      left: 12% !important;\n      width: 66% !important;\n      position: relative !important;\n  }\n\n  .custom-list {\n    list-style-type: none; \n}\n\n.custom-list li:before {\n    content: \"-\"; \n    margin-right: 0.5em; \n}\n\n.pl-zero{\n  padding-left: 0px;\n}\n\n@media (max-width: 1186px) {\n  .price-table th, .price-table td {\n    font-size: 11px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
