import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import apiService from "../../services/api.service";
import { useNavigate } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import "../../css/AdminCoursePreview.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import "../../css/global.css";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Table,
} from "react-bootstrap";

const CoursePreview = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [versions, setversionsValue] = useState(null);
  const [latestversions, setlatestversions] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(latestversions);
  const [loading, setLoading] = useState(true);
  
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`/Admin/${courseId}`);
  };

  // Function to extract video ID
  const getYouTubeID = (url) => {
    const videoUrl = url?.replace(/^https:\/\/youtu.be\//, "");
    return videoUrl;
  };


  const handleDeleteClick =async () => {
    const confirmationResult = await Swal.fire({
      title: "Are you sure you want to delete the course ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
    if (confirmationResult.isConfirmed) {
      try {
        apiService.deleteCourseById(courseId, selectedVersion).then((response) => {
          if (response?.status === 200) {
            Swal.fire({
              title: "Course Deleted",
              html: "The course has been Deleted successfully. <br> After deleting this course, adjust the prerequisite requirements for all affected courses.",
              icon: "success",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(`/AdminManageCourse`);
              }
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "An error occurred while deleting the course. Please try again later.",
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
              customClass: {
                title: "text-center",
                content: "text-center",
                confirmButton: "text-center",
              },
            });
          }
        });
      } catch (error) {
        console.error("Error during delete course:", error.message);
      }
    }
    
  };

  useEffect(() => {
    apiService.getCourseById(courseId).then((response) => {
      setCourse(response?.data.course);
    });
    apiService.getAllVersionForCourses().then((response) => {
      if (response && response.courses) {
        const foundCourse = response.courses.find((c) => c.id === courseId);
        if (foundCourse) {
          setlatestversions(foundCourse.latestVersion);
          setversionsValue(foundCourse.versions);
          setSelectedVersion(foundCourse.latestVersion || "");
        } else {
          console.error("Course not found in the API response.");
        }
        setLoading(false)
      } else {
        console.error("Invalid API response structure.");
      }
    });
  }, [courseId]);

  // useEffect(() => {
  //   const fetchVideoDurations = async () => {
  //     const durations = {};
  //     if (course) {
  //       for (const lesson of course.lessons) {
  //         if (lesson.videoUrl && Array.isArray(lesson.videoUrl)) {
  //           for (const videoUrl of lesson.videoUrl) {
  //             const iframe = document.createElement("iframe");
  //             iframe.src = videoUrl;
  //             await new Promise((resolve) => {
  //               iframe.addEventListener("loadedmetadata", () => {
  //                 durations[videoUrl] = iframe.duration;
  //                 resolve();
  //               });
  //               iframe.load();
  //             });
  //           }
  //         } else if (typeof lesson.videoUrl === "string") {
  //           const iframe = document.createElement("iframe");
  //           iframe.src = lesson.videoUrl;
  //           await new Promise((resolve) => {
  //             iframe.addEventListener("loadedmetadata", () => {
  //               durations[lesson.videoUrl] = iframe.duration;
  //               resolve();
  //             });
  //             iframe.load();
  //           });
  //         }
  //       }
  //       setVideoDurations(durations);
  //       console.log(course?);
  //     }
  //   };

  //   fetchVideoDurations();
  // }, [course]);

  if (!course) {
    return <div>Loading...</div>;
  }
  const getButtonVariant = () => {
    switch (course.courseStatus) {
      case "publish":
        return "success";
      case "pending":
        return "warning";
      case "draft":
        return "info";
      case "viewOnly":
        return "viewOnly"
      default:
        return "secondary";
    }
  };

  const getButtonText = () => {
    switch (course.courseStatus) {
      case "publish":
        return "Published";
      case "pending":
        return "On Hold";
      case "draft":
        return "Saved";
      case "viewOnly":
        return "viewOnly"
      default:
        return "Unknown Status";
    }
  };
  const handleChangeVersions = (event) => {
    setSelectedVersion(event.target.value);
    apiService
      .previewCourseByVersion(courseId, event.target.value)
      .then((response) => {
        setCourse(response.data.course);
      });
  };
  const handlenavigateAddCourse = () => {
    navigate(`/Admin`);
  };
  const handlenavigateManageCourse = () => {
    navigate(`/AdminManageCourse`);
  };


  return (
    <div className="CoursePreview">
      <AdminHeader className="mb-4" />
      <br />
      <Container>
        <br />
        <Row>
          <Col sm={10}>
            <h3>COURSE PREVIEW</h3>
          </Col>
          <Col sm={2}>
            {" "}
            <Button onClick={handlenavigateManageCourse}>
              Back To Courses
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Card style={{ margin: "3%" }}>
              <Card.Body>
                <Row>
                  <Col xs={12} sm={8}>
                    <h3 className="course-title">TITLE:{course.courseTitle}</h3>
                  </Col>
                  <Col sm={2} className="d-flex justify-content-end">
                    <Button className="mt-2" onClick={handleEditClick}>
                      <FontAwesomeIcon icon={faEdit} /> EDIT
                    </Button>
                  </Col>
                  <Col sm={2} className="d-flex justify-content-end">
                    <Button className="mt-2" onClick={handleDeleteClick}>
                      <FontAwesomeIcon icon={faTrashAlt} /> DELETE
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <img
                      src={course.thumbnailUrl}
                      alt={course.courseTitle}
                      style={{ width: "70%", height: "auto" }}
                    />
                  </Col>
                  <Col sm={8}>
                    <Row className="course-details">
                      <Col
                        xs={12}
                        sm={2}
                        className="custom-dropdown-col d-flex justify-content-end"
                        style={{ width: "auto", marginTop:"5px"}}
                      >
                        <Form.Control
                          as="select"
                          className="course-version custom-dropdown"
                          value={selectedVersion}
                          onChange={handleChangeVersions}
                        >
                          {versions &&
                            versions
                              .sort((a, b) => b - a)
                              ?.map((version, index) => (
                                <option key={index} value={version}>
                                  Version {version}
                                </option>
                              ))}
                        </Form.Control>
                      </Col>
                    </Row>
                    <Row className="course-details">
                      <Col xs={12}>
                        <p className="course-description">
                          DESCRIPTION:{course.courseDescription}
                        </p>
                      </Col>
                    </Row>
                    <Row className="course-details">
                      <Col xs={6}>
                        <p className="course-price">
                          PRICE:{" "}
                          {course.coursePrice === 0
                            ? "Free"
                            : course.coursePrice}
                        </p>
                      </Col>
                      <Col xs={6}>
                        <Button
                          className="btn-status"
                          variant={getButtonVariant()}
                        >
                          {getButtonText()}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Card className="mt-4">
                  <Card.Body>
                    <h2>Lesson Details</h2>
                    <Table striped bordered responsive>
                      <thead>
                        <tr>
                          <th> </th>
                          <th>Video</th>
                          <th>Title</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {course &&
                          course?.lessons &&
                          course?.lessons?.map((lesson, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {lesson.video ? (
                                  <>
                                    <iframe
                                      src={`${process.env.REACT_APP_VIMEO_VIDEO}${lesson.video}`}
                                      width="200"
                                      height="150"
                                      frameborder="0"
                                      allow="autoplay; fullscreen"
                                      allowfullscreen
                                    ></iframe>
                                  </>
                                ) : (
                                  "Not uploaded"
                                )}
                              </td>
                              {/* <td>
                                {videoDurations[lesson.videoUrl]
                                  ? videoDurations[lesson.videoUrl].toFixed(2) +
                                    " seconds"
                                  : "Calculating..."}
                              </td> */}
                              <td>{lesson.title}</td>
                              <td>{lesson.description}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
                <Row>
                  <Col sm={12}>
                    <Card className="mt-4">
                      <Card.Body>
                        <div className="evaluation-questions mt-4">
                          <h2>Evaluation Questions</h2>
                          <Table striped bordered responsive>
                            <thead>
                              <tr>
                                <th> </th>
                                <th>Question</th>
                                <th>Answer</th>
                              </tr>
                            </thead>
                            <tbody>
                              {course.evaluations?.map((evaluation, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{evaluation.question}</td>
                                  <td>
                                    {evaluation.type === "q&a" ? (
                                      evaluation?.answer
                                    ) : (
                                      <ul>
                                        {evaluation?.options?.map(
                                          (option, optionIndex) => {
                                            const isCorrect =
                                              evaluation?.correctOption
                                                .split(",")
                                                ?.includes(option);
                                            return (
                                              <li
                                                key={optionIndex}
                                                className={`${
                                                  isCorrect
                                                    ? "correct-answer"
                                                    : ""
                                                }`}
                                              >
                                                {option}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
};

export default CoursePreview;