import React from 'react';
import "../../css/termsAndService.css";


const TermsAndConditions = () => {
  return (
    <div className="terms-container">
    <h1 className="title">TERMS OF SERVICE AGREEMENT</h1>
    <p className="paragraph">Last Updated: September 2, 2024</p>
    
    <h2 className="section-title">Welcome to Operations Science Online Courses (the “Site”). These Terms of Service (“Terms”) govern your use of the Site and the purchase and access to our online courses. By accessing or using the Site, you agree to be bound by these Terms. If you do not agree to these Terms, do not use the Site or purchase any courses.</h2>

    <h2 className="section-title">1. Use of the Site</h2>
    <p className="paragraph">
      1.1 Eligibility: You must be at least 18 years old to use the Site and purchase courses. By using the Site, you represent and warrant that you meet this eligibility requirement.
    </p>
    <p className="paragraph">
      1.2 Account Registration: You may need to create an account to access certain features of the Site. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to provide accurate, current, and complete information during the registration process.
    </p>

    <h2 className="section-title">2. Course Purchases and Access</h2>
    <p className="paragraph">
      2.1 Course Availability: Once you purchase a course, you will have access to the course content for a specified period, typically 6 weeks from the date of purchase. After this period, access to the course will expire, and you will need to repurchase the course if you wish to continue viewing it.
    </p>
    <p className="paragraph">
      2.2 No Refunds: All purchases are final. We do not offer refunds for any courses purchased on the Site, regardless of whether the courses were accessed or completed.
    </p>
    <p className="paragraph">
      2.3 Course Content: We reserve the right to update or modify course content at any time without notice. You acknowledge that the content may change at any time.
    </p>
    <p className="paragraph">
      2.4 Course Pricing: We reserve the right to update or modify course pricing at any time without notice.
    </p>
    <h2 className="section-title">3. AI Chatbot</h2>
    <p className="paragraph">
      3.1 AI Chatbot Access: Our online courses may provide access to an AI Chatbot for additional assistance. The AI Chatbot is designed to help with course-related inquiries but may not always provide correct answers.
    </p>
    <p className="paragraph">
      3.2 Limitations: You understand and agree that the AI Chatbot may generate incorrect, incomplete, or misleading responses. The information provided by the AI Chatbot should not be solely relied upon for any decision-making. We disclaim all liability for any actions taken based on the AI Chatbot’s responses.
    </p>

    <h2 className="section-title">4. User Conduct</h2>
    <p className="paragraph">
      4.1 Prohibited Activities: You agree not to engage in any of the following prohibited activities:
    </p>
    <ul>
      <li>Violating any local, state, national, or international law.</li>
      <li>Engaging in any conduct that could damage, disable, or impair the Site.</li>
      <li>Attempting to gain unauthorized access to any portion or feature of the Site.</li>
      <li>Using the Site for any unauthorized commercial purpose.</li>
    </ul>
    <p className="paragraph">
      4.2 User Content: If you post or upload any content to any site owned by the Operations Science Institute, you grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, distribute, modify, run, copy, publicly perform, display, translate, and create derivative works of your content.
    </p>

    <h2 className="section-title">5. Intellectual Property</h2>
    <p className="paragraph">
      5.1 Ownership: All content on the Site, including text, graphics, logos, images, audio and video recordings, documents, PDFs, spreadsheets, and software, is the property of the Operations Science Institute or its licensors and is protected by intellectual property laws.
    </p>
    <p className="paragraph">
      5.2 Limited License: You are granted a limited, non-exclusive, non-transferable license to access and use the Site and the course content for your personal, non-commercial use for the extent of your access to the courses. You may not copy, download, or reproduce the lesson videos or slides. Providing the lesson videos, slides, and support material including documents, spreadsheets, and PDFs to open source, shared content with external parties, or to third-party websites is strictly forbidden. Resale of Site content in part or in whole, including derivative works, is prohibited. No other use is permitted without our prior written consent.
    </p>

    <h2 className="section-title">6. Disclaimers and Limitation of Liability</h2>
    <p className="paragraph">
      6.1 Disclaimers: The Site and all content and services provided through it are provided on an "as-is" and "as-available" basis. We make no warranties, express or implied, regarding the Site or its content.
    </p>
    <p className="paragraph">
      6.2 Limitation of Liability: To the fullest extent permitted by law, the Operations Science Institute shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from (i) your use or inability to use the Site; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein.
    </p>

    <h2 className="section-title">7. Indemnification</h2>
    <p className="paragraph">
      You agree to indemnify, defend, and hold harmless the Operations Science Institute, its affiliates, and their respective officers, directors, employees, agents, licensors, and suppliers from and against all claims, liabilities, damages, losses, costs, expenses, fees (including reasonable attorneys' fees) arising out of or related to your violation of these Terms or your use of the Site.
    </p>

    <h2 className="section-title">8. Account Termination</h2>
    <p className="paragraph">
      We reserve the right to terminate your account at any time for any reason. Upon termination, you may be eligible for a pro-rated refund as determined at the sole discretion of the Operations Science Institute.
    </p>

    <h2 className="section-title">9. Changes to the Terms</h2>
    <p className="paragraph">
      We reserve the right to modify these Terms at any time. If we make changes, we will provide notice by updating the "Last Updated" date above. Your continued use of the Site after any such changes constitutes your acceptance of the new Terms.
    </p>

    <h2 className="section-title">10. Governing Law</h2>
    <p className="paragraph">
      These Terms shall be governed by and construed in accordance with the laws of Illinois, United States of America, without regard to its conflict of law principles.
    </p>

    <h2 className="section-title">11. Contact Us</h2>
    <p className="paragraph">
      If you have any questions about these Terms, please contact us at info@opscience.org.
    </p>
    
    <p className="paragraph">
      By using the Site and purchasing our courses, you agree to these Terms. Please read them carefully and ensure you understand them before making a purchase. Thank you for choosing the Operations Science Institute’s Online Courses.
    </p>
  </div>
  );
};

export default TermsAndConditions;
