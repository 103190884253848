import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import AdminHeader from "./Admin/AdminHeader";
import { firestore } from "../Common/firebase";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Modal,
} from "react-bootstrap";
import apiService from "../services/api.service";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const MasterPage = () => {
  const [users, setUsers] = useState([]);
  const [searchEmail, setSearchEmail] = useState("");
  const [selectedRoles, setSelectedRoles] = useState({});
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userCourses, setUserCourses] = useState([])
  const [selectedUserId, setSelectedUserId] = useState(null)

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      // const usersSnapshot = await firestore.collection("users").get();
      // const usersData = usersSnapshot.docs.map((doc) => ({
      //   id: doc.id,
      //   ...doc.data(),
      // }));
      apiService.getUsers().then((response)=>{
        setUsers(response.data);
      })
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleRoleChange = (userId, newRole) => {
    setSelectedRoles((prevRoles) => ({ ...prevRoles, [userId]: newRole }));
    setIsRoleSelected(true);
  };

  const handleAssignRole = async (userId) => {
    try {
      await firestore.collection("users").doc(userId).update({
        role: selectedRoles[userId],
      });
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, role: selectedRoles[userId] } : user
        )
      );
      setSelectedRoles((prevRoles) => ({ ...prevRoles, [userId]: undefined }));
      setIsRoleSelected(false);
      await Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Role assigned successfully",
        showCloseButton: true,
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error updating role:", error);
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to assign role",
        showCloseButton: true,
        confirmButtonText: "OK",
      });
    }
  };

  const handleCancel = (userId) => {
    setSelectedRoles((prevRoles) => ({ ...prevRoles, [userId]: undefined }));
    setIsRoleSelected(false);
  };

  const handleDeleteUser = async (userId, email) => {
    try {
      const confirmed = await Swal.fire({
        title: "Are you sure you want to delete the user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowOutsideClick: false,
      });
      if (confirmed.isConfirmed) {

        await apiService.deleteUser(email)
        await firestore.collection("users").doc(userId).delete();

        await fetchUsers();
        Swal.fire({
          title: "Deleted",
          text: "User Deleted Successfully",
          icon: "success",
        });
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      Swal.fire("Error", "Failed to delete user.", "error");
    }
  };

  const handleBlockUser = async (userId, isBlocked) => {
    try {
      await firestore.collection("users").doc(userId).update({
        isBlocked: !isBlocked,
      });
      await fetchUsers();
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: isBlocked ? "User unblocked successfully" : "User blocked successfully",
        showCloseButton: true,
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error updating user status:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update user status",
        showCloseButton: true,
        confirmButtonText: "OK",
      });
    }
  };

  const handleSearchChange = (e) => {
    setSearchEmail(e.target.value);
  };

  const exportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "users_data";

    const formattedData = users.map((user) => ({
      Email: user.email,
      Role: user.role,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    const url = URL.createObjectURL(data);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName + fileExtension;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const filteredUsers = searchEmail
    ? users.filter((user) =>
        user.email.toLowerCase().includes(searchEmail.toLowerCase())
      )
    : users.slice(indexOfFirstUser, indexOfLastUser);

  
    const listUserCourse = (userId)=>{
      setSelectedUserId(userId)
      setShowDeleteModal(true)
      apiService.getPurchasedCourses(userId).then((response)=>{
        setUserCourses(response.data)
      })  
    }


    const handleDeleteCourseFromUser =async (courseId, userId)=>{
        if(userId, courseId){
          const confirmed = await Swal.fire({
            title: "Are you sure you want to delete the course from the user?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            allowOutsideClick: false,
          });
          if (confirmed.isConfirmed) {
            apiService.deletePurchasedCourse(userId, courseId).then((response)=>{
              console.log(userId, courseId);
              listUserCourse(userId)
              Swal.fire({
                title: "Deleted",
                text: "Course Deleted Successfully",
                icon: "success",
              });
            }).catch((error)=>{
    
            });
          }     
        }
    }


    function formatDate(dateString) {
      const date = new Date(dateString);
      const options = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    }

  return (
    <div>
      <AdminHeader />
      <Container className="mt-4">
        <Row>
          <Col>
            <h3>USER MANAGEMENT</h3>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4}>
            <input
              type="text"
              className="form-control"
              placeholder="Search by email"
              value={searchEmail}
              onChange={handleSearchChange}
            />
          </Col>
          <Col sm={2}>
            <button className="btn btn-success" onClick={exportToExcel}>
              Export to Excel
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Created Date</th>
                      <th>Role</th>
                      <th>Action</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user) => (
                      <>
                        <tr key={user.id}>
                          <td>{user.email}</td>
                          <td>{formatDate(user.creationTime)}</td>
                          <td>{user.role}</td>
                          <td>
                            <select
                              value={selectedRoles[user.id] || user.role}
                              onChange={(e) =>
                                handleRoleChange(user.id, e.target.value)
                              }
                            >
                              <option value="User">User</option>
                              <option value="admin">Admin</option>
                              <option value="Master">Master</option>
                            </select>
                            <button
                              className="btn btn-primary ml-2 margin-3"
                              onClick={() => handleAssignRole(user.id)}
                              disabled={
                                !selectedRoles[user.id] || !isRoleSelected
                              }
                            >
                              Assign Role
                            </button>
                            <button
                              className="btn btn-secondary ml-2 margin-3"
                              onClick={() => handleCancel(user.id)}
                              disabled={
                                !selectedRoles[user.id] || !isRoleSelected
                              }
                            >
                              Cancel
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn btn-warning ml-2 margin-3"
                              onClick={() =>
                                handleBlockUser(user.id, user.isBlocked)
                              }
                            >
                              {user.isBlocked ? "Unblock" : "Block"}
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn btn-warning ml-2 margin-3"
                              onClick={() => listUserCourse(user.id)}
                            >
                              Delete course
                            </button>
                            <Modal
                              show={showDeleteModal}
                              onHide={() => setShowDeleteModal(false)}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  Remove the course from the user's account.
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form>
                                  {userCourses && userCourses.length > 0 ? (
                                    userCourses.map((userCourse) => {
                                      return (
                                        <div className="d-flex justify-content-between ">
                                          <Form.Group>
                                            {userCourse.courseTitle}
                                          </Form.Group>
                                          <Button
                                            variant="link"
                                            className="icon-button"
                                            onClick={() =>
                                              handleDeleteCourseFromUser(
                                                userCourse.courseId,
                                                selectedUserId
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faTrashAlt}
                                            />
                                          </Button>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <span>
                                      This user hasn't purchased any courses
                                      yet.
                                    </span>
                                  )}
                                </Form>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={() => {
                                    setShowDeleteModal(false);
                                    setSelectedUserId(null);
                                  }}
                                >
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </td>
                          <td>
                            <button
                              className="btn btn-danger ml-2 margin-3"
                              onClick={() => handleDeleteUser(user.id, user.email)}
                            >
                              <FaTrash />
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={2} className="text-center">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage - 1)}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
              </li>
              {[...Array(Math.ceil(users.length / usersPerPage)).keys()].map(
                (number) => (
                  <li
                    key={number + 1}
                    className={`page-item ${
                      currentPage === number + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => paginate(number + 1)}
                    >
                      {number + 1}
                    </button>
                  </li>
                )
              )}
              <li
                className={`page-item ${
                  currentPage === Math.ceil(users.length / usersPerPage)
                    ? "disabled"
                    : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage + 1)}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MasterPage;
