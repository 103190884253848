import React from 'react';
import "../../css/termsAndService.css";

const PrivacyPolicy = () => {
  return (
    <div className="terms-container">
      <h1 className="title">Privacy Policy</h1>
      <div className="paragraph">Last Updated: June 25, 2024</div>

      <div className="paragraph">
        This Privacy Policy explains how the Operations Science Institute's
        Operations Science Online Courses website, www.learn.opscience.org (the
        "Website"), uses and protects any information that you provide when
        using our services, including the chatbot feature.
      </div>

      <h2 className="section-title">Information Collection</h2>

      <div className="paragraph">
        <p>1. General Site Usage:</p>
        <ul>
          <li>
            We do not collect any personal information from users unless it is
            voluntarily provided, such as when creating an account or making a
            purchase.
          </li>
          <li>
            We may collect non-personal information such as browser type,
            operating system, and the pages you visit on our site to monitor and
            understand site usage and to help improve the site.
          </li>
        </ul>
      </div>

      <div className="paragraph">
        <p>2. Chatbot Usage:</p>
        <ul>
          <li>
            The chatbot on our Website is designed to assist with lessons and
            answer questions about our online course material.
          </li>
          <li>
            We do not collect any personal information from you when you use the
            chatbot.
          </li>
          <li>
            We may collect chatbot data in the aggregate to monitor and
            understand site usage and to help improve the chatbot's
            functionality.
          </li>
        </ul>
      </div>

      <div className="paragraph">
        <p>3. Email Addresses:</p>
        <ul>
          <li>
            We retain email addresses provided during account registration and
            purchase.
          </li>
          <li>
            We will only use email addresses to communicate with users as
            indicated by their email preferences in their profile.
          </li>
        </ul>
      </div>

      <h2 className="section-title">Information Use</h2>

      <div className="paragraph">
        <p>1. General Site Usage:</p>
        <ul>
          <li>
            The non-personal information collected is used to enhance your
            experience on our Website, ensure the proper functioning of our
            services, and improve our site content and offerings.
          </li>
        </ul>
      </div>

      <div className="paragraph">
        <p>2. Chatbot Usage:</p>
        <ul>
          <li>
            The data collected by the chatbot is used solely to respond to your
            questions and provide information about our online course material.
          </li>
          <li>
            Aggregate data from the chatbot may be used to improve the chatbot’s
            performance and usefulness for website users.
          </li>
        </ul>
      </div>

      <h2 className="section-title">Information Sharing</h2>

      <div className="paragraph">
        <ul>
          <li>
            We do not share your personal information with any third parties.
          </li>
          <li>
            Aggregate data that does not identify individuals may be shared with
            partners to improve our services.
          </li>
        </ul>
      </div>

      <h2 className="section-title">Security</h2>

      <div className="paragraph">
        <ul>
          <li>
            We take appropriate security measures to protect your information
            from unauthorized access, disclosure, modification, or destruction.
          </li>
          <li>
            These measures include secure servers, encryption, and other
            industry-standard practices.
          </li>
        </ul>
      </div>

      <h2 className="section-title">Changes to this Privacy Policy</h2>

      <div className="paragraph">
        <ul>
          <li>We may update this privacy policy from time to time.</li>
          <li>
            We will notify you of any significant changes by posting a notice on
            our Website.
          </li>
          <li>
            We encourage you to review this policy periodically to stay informed
            about how we are protecting your information.
          </li>
        </ul>
      </div>

      <h2 className="section-title">Contact Us</h2>

      <div className="paragraph">
        If you have any questions about this Privacy Policy, please contact us
        at <a href="mailto:info@opscience.org">info@opscience.org</a>.
      </div>

      <div className="paragraph">
        By using our Website and services, you consent to this Privacy Policy.
        Please ensure you understand it before using our site or services. Thank
        you for choosing the Operations Science Institute’s Online Courses.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
