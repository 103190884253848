import React, { useState, useEffect } from 'react';
import { Container, Table, Dropdown, Row, Col } from 'react-bootstrap';
import apiService from '../../services/api.service';

const UserExploreCourses = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState('');

  useEffect(() => {
    // apiService.getAllCourses()
    //   .then(response => setCourses(response.courses))
    //   .catch(error => console.error('Error loading courses:', error));
  }, []);

  const handleCertificateChange = (selectedValue) => {
    setSelectedCertificate(selectedValue);
  };

  return (
    <Container className="mt-4">
      <h2 className="text-center mb-4">Explore Courses</h2>
      <Row className="mb-3">
        <Col xs={12} md={6} lg={4}>
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="certificateDropdown">
              Select Certificate
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleCertificateChange('Option 1')}>OSI</Dropdown.Item>
              <Dropdown.Item onClick={() => handleCertificateChange('Option 2')}>Inventory flow</Dropdown.Item>
              <Dropdown.Item onClick={() => handleCertificateChange('Option 3')}>osf</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Table striped bordered hover responsive className="shadow p-3 mb-5 bg-white rounded">
        <thead>
        <tr>
            <th colSpan="3" className="text-center py-3 bg-success text-white">
              Select Course by Certificate
            </th>
          </tr>
          <tr className="bg-white text-dark">
          <th>Select</th>
            <th>Title</th>
            <th>Description</th>
          </tr>
        </thead>
        {/* <tbody>
          {courses.map(course => (
            <tr key={course._id}>
              <td><input type="checkbox" /></td>
              <td>{course.Title}</td>
              <td>{course.Description}</td>
            </tr>
          ))}
        </tbody> */}
      </Table>
    </Container>
  );
};

export default UserExploreCourses;
