import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element, role, fallbackPath }) => {

  const userRole = localStorage.getItem('userRole');
  
  if (role.includes(userRole)) {
    return element;
  } else {
    return <Navigate to="/Login" />;
  }

};

export default PrivateRoute;
