import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import apiService from "../../services/api.service";
import AdminHeader from "./AdminHeader";
import { Container, Row, Col, Button, Dropdown, Card } from "react-bootstrap";
import "../../css/ManageAdminCourse.css";
import "../../css/global.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSortAmountDesc, faSortAmountUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const CoursesList = () => {
  const [courses, setCourses] = useState([]);
  const [selectedPrerequisites, setSelectedPrerequisites] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    apiService.getAllCourses().then((response) => {
      if (response && response.courses) {
        setCourses(response.courses);
      } else {
        console.error("Invalid API response structure.");
      }
    });
  }, []);
  const handlePrerequisitesChange = (event) => {
    const selectedOptions = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedPrerequisites(selectedOptions);
  };
  const handleAddcourse = () => {
    navigate(`/Admin`);
  };
  const handleEdit = (id) => {
    navigate(`/Admin/${id}`);
  };
  const handlePreview = (id) => {
    navigate(`/AdminCoursePreview/${id}`);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then((result) => {
      if (result.isConfirmed) {
        apiService.deleteCourse(id).then((response) => {
          Swal.fire({
            title: "Course Deleted",
            text: "The course has been Deleted",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              apiService.getAllCourses().then((response) => {
                setCourses(response.courses);
              });
            }
          });
        });
      }
    });
  };

  const handleSortByAscending = () => {
    setCourses((prevState) => [
      ...prevState.sort((a, b) => a.courseTitle.localeCompare(b.courseTitle)),
    ]);
  };

  const handleSortByDescending = () => {
    setCourses((prevState) => [
      ...prevState.sort((a, b) => b.courseTitle.localeCompare(a.courseTitle)),
    ]);
  };

  return (
    <div className="ManageAllCourse">
      <AdminHeader className="mb-4" />
      <br />
      <br />
      <Container>
        <Container>
          <Row>
            <Col sm={8}>
              <h3>COURSES/MANAGE COURSES</h3>
            </Col>
            <Col sm={2}>
              {" "}
              <Button onClick={handleAddcourse}>
                {" "}
                <FontAwesomeIcon icon={faPlus} className="mr-2" /> ADD COURSE
              </Button>
            </Col>
            <Col sm={1}>
              <Button onClick={handleSortByAscending}>
                <FontAwesomeIcon icon={faSortAmountUp} className="mr-2" />
              </Button>
            </Col>
            <Col sm={1}>
              <Button onClick={handleSortByDescending}>
                <FontAwesomeIcon icon={faSortAmountDesc} className="mr-2" />
              </Button>
            </Col>
          </Row>
          <br />
        </Container>
        {courses?.length === 0 ? (
          <Row className="course-cards">
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>No Courses Found</Card.Title>
                  <Card.Text>
                    {" "}
                    Please add courses to view.{" "}
                    <Link to="/Admin">
                      <span className="text-primary">Add a course</span>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row className="course-cards">
            {courses.map((course) => (
              <Col
                key={course?.id}
                className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4"
              >
                <div className="course-card">
                  <div className="Course_cardIn">
                    <Row>
                      <Col sm={4} className="d-flex align-items-center">
                        <div className="image-column">
                          <img
                            src={course.thumbnailUrl}
                            alt={course.courseTitle}
                            className="img-fluid"
                          />
                        </div>
                      </Col>
                      <Col sm={8}>
                        <div className="course-info">
                          <div className="title-bar">
                            <Row>
                              <Col sm={10}>
                                <h3>{course.courseTitle}</h3>
                              </Col>
                              <Col sm={2} className="HoverDot">
                                <Dropdown className="dropdown">
                                  <Dropdown.Toggle
                                    variant="secondary"
                                    id="dropdown-basic"
                                  >
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => handlePreview(course.id)}
                                    >
                                      Preview
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => handleEdit(course.id)}
                                    >
                                      Edit
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </div>
                          <p>
                            {course?.courseDescription?.substring(0, 100)}...
                          </p>
                          <Row>
                            <Col sm={4}>
                              <p>PRICE:{course?.coursePrice}</p>
                            </Col>
                            <Col sm={8}>
                              <p>LESSONS:{course?.lessons?.length}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={2}>
                              <h3>version:{course?.version}</h3>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default CoursesList;
