// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  background-color: #5C9933;
  color: #ffffff;
  font-family: "Nanum Gothic", Sans-serif !important;
}

.navbar-brand {
  margin-left: 20px;
}

.navbar-nav .nav-link {
  color: #ffffff !important;
}

.navbar-nav .nav-link:hover {
  background-color: #5C9933;
}

.navbar-nav .nav-item.dropdown:hover .dropdown-menu {
  display: block;
  background-color: rgba(121, 186, 78, 0.7);
  border-radius: 10px !important;
}

.dropdown-menu a:hover {
  background-color: #5CAE3D;
  transition: background-color 0.3s ease-out;
}
`, "",{"version":3,"sources":["webpack://./src/css/signUpHeader.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,kDAAkD;AACpD;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,yCAAyC;EACzC,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,0CAA0C;AAC5C","sourcesContent":[".navbar {\n  background-color: #5C9933;\n  color: #ffffff;\n  font-family: \"Nanum Gothic\", Sans-serif !important;\n}\n\n.navbar-brand {\n  margin-left: 20px;\n}\n\n.navbar-nav .nav-link {\n  color: #ffffff !important;\n}\n\n.navbar-nav .nav-link:hover {\n  background-color: #5C9933;\n}\n\n.navbar-nav .nav-item.dropdown:hover .dropdown-menu {\n  display: block;\n  background-color: rgba(121, 186, 78, 0.7);\n  border-radius: 10px !important;\n}\n\n.dropdown-menu a:hover {\n  background-color: #5CAE3D;\n  transition: background-color 0.3s ease-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
